import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';

import { loadUser,logout } from '../flux/actions/authActions';
import { clearReservations} from '../flux/actions/marketplaceActions';

const useStyles = makeStyles((theme) => ({

  grow: {
    flexGrow: "1",
    background: "none",
    width: "100vw",
    position: "fixed",
    top: 0,
  },

  /*
  appbar: {
    flexGrow: "1",
    //background: "none",
    witdh: "100vw",
    zIndex: 5,
  },
  */
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  lv2Item: {
    paddingLeft: '1.8rem',
    background: '#8f8f8f',
    },
  menuIcon: {    
    //color: 'yellow',
    width: '20px',
    position: 'absolute',
    right: '0',
    top: '6',   
    //width: '5px',
    //height: '0px',
    borderbottom: '30px solid transparent',
    bordertop: '30px solid transparent',
    borderleft: '30px solid #2f2f2f',
    fontsize: '0px',
    lineheight: '0px',
  },

  title: {
    //display: 'block',    
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    //display: 'none',
    //[theme.breakpoints.up('sm')]: {
    //  display: 'block',
    //},
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  link: {
    underline: "none",
    marginRight: "10px",
    color: '#FFF'
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  rightarrow: {
    //position: 'absolute',
    //right: '0',
    //top: '0',   
    //width: '0px',
    //height: '0px',    
    //borderbottom: '30px solid transparent',
    //bordertop: '30px solid transparent',
    //borderleft: '30px solid #2f2f2f',
    //fontsize: '0px',
    //lineheight: '0px',
    justifyContent: "flex-end",
    justify_content: "flex-end",
    align: "right"
    
  }

}));



const AppNavbar = (auth) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [showAdminMenu, setShowAdminMenu] = React.useState(false);
  const [showViewMenu, setShowViewMenu] = React.useState(false);

  const user = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  if(!user) {
    dispatch(loadUser());
  }

  useEffect(() => {
    if(!user) {
      dispatch(loadUser());
    }
  },[user,dispatch]);

  const showHideAdminMenu = () => {
    setShowAdminMenu(!showAdminMenu);
  }

  const showHideViewMenu = () => {
    setShowViewMenu(!showViewMenu);
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const logoutUser = () => {    
    dispatch(logout()); 
    dispatch(clearReservations());
    handleMenuClose();
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const showParentMenuIcon = (showMenu) => {
    if(showMenu) {
      return (<KeyboardArrowUpIcon className={classes.menuIcon}/>);
    } else {      
      return (<KeyboardArrowDownIcon className={classes.menuIcon}/>);
    }
  }

  const adminMenu = (    
    <>
    <MenuItem component={Link} to="/admin/kayttajat" className={classes.lv2Item} onClick={handleMenuClose}>Käyttäjät</MenuItem> 
    <MenuItem component={Link} to="/admin/varaukset" className={classes.lv2Item} onClick={handleMenuClose}>Varaukset</MenuItem>
    </>                            
  );

  const menuId = 'primary-search-account-menu';
  const renderMenu = (

    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      
      {user && user.isAdmin ? 
        <MenuItem onClick={showHideAdminMenu}>Hallinta{showParentMenuIcon(showAdminMenu)}</MenuItem> : null
      }

      {user && user.isAdmin && showAdminMenu ? adminMenu : null}

      {user && user.isAdmin ?  <Divider/> : null}
    

      <MenuItem onClick={showHideViewMenu}>Myyntipaikat{showParentMenuIcon(showViewMenu)}</MenuItem>

      {showViewMenu ? 
      <div>
      
      <MenuItem component={Link} to="/paikkavaraus/kartta" className={classes.lv2Item} onClick={handleMenuClose}>Kartta</MenuItem>
      
      </div>
       : null}     
       <Divider/> 

      <MenuItem component={Link} to="/tilini" onClick={handleMenuClose}>Tilini</MenuItem>
      <MenuItem component={Link} to="/" onClick={logoutUser}>Kirjaudu ulos</MenuItem>
    </Menu>
  );



  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/*
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={6} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
  */}
      {user && user.isAdmin ? 
        <MenuItem onClick={showHideAdminMenu}>Hallinta{showParentMenuIcon(showAdminMenu)}</MenuItem> : null
      }

      {user && user.isAdmin && showAdminMenu ? adminMenu : null}

      {user && user.isAdmin ?  <Divider/> : null}
    

      <MenuItem onClick={showHideViewMenu}>Myyntipaikat{showParentMenuIcon(showViewMenu)}</MenuItem>

      {showViewMenu ? 
      <div>
      
      <MenuItem component={Link} to="/paikkavaraus/kartta" className={classes.lv2Item} onClick={handleMenuClose}>Kartta</MenuItem>
      
      </div>
       : null}     
       <Divider/> 

      <MenuItem component={Link} to="/tilini" onClick={handleMenuClose}>Tilini</MenuItem>
      <MenuItem component={Link} to="/" onClick={logoutUser}>Kirjaudu ulos</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar className={classes.appbar} position="static" elevation={0}>
        <Toolbar>
          {/*}
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
        */}

          <Link to="/" className={classes.link} color="inherit" >
            <HomeIcon />
          </Link>
          <Typography className={classes.title} variant="h6" noWrap>
            Laurin Markkinat Mynämäellä 10.8.2024
          </Typography>

          {/*
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
            */}
          < div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {/*
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            */}
            <IconButton
              edge="end"
              aria-label="Valikko"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            

            
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar >
      </AppBar >
      {renderMobileMenu}
      {renderMenu}
    </div >
  );
};

export { AppNavbar as default }