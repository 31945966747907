
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme as createThemeV4 } from "@material-ui/core/styles";
import { createTheme as createThemeV5 } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderV4, StylesProvider } from '@material-ui/core/styles';


import './App.css';

import StartPage from './components/StartPage';
import LoginModal from './components/auth/LoginModal';
import RegisterModal from './components/auth/RegisterModal';
import RenewPWDModal from './components/auth/RenewPWDModal';
import ReservationPage from './components/ReservationPage';
import MarkkinaInfoPage from './components/MarkkinaInfoPage';
import { Provider } from 'react-redux';
import store from './flux/store';
import { createGenerateClassName } from '@material-ui/core/styles';

const App = () => {

  const themeV4 = createThemeV4({
    palette: {
      type: 'light',
      primary: {
        main: '#004a95',
      },
      secondary: {
        main: '#f50057',
        //main: '#f5bb57',
      },
    },
  });

  const themeV5 = createThemeV5({
    palette: {
      primary: {
        main: themeV4.palette.primary.main,
      },
    },
  });

  

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: 'mui-jss',
});


  return (
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
      <BrowserRouter>
      {
      //<MuiThemeProvider theme={theme}>
      }
      <ThemeProviderV4 theme={themeV4}>
        <ThemeProviderV5 theme={themeV5}>
      <CssBaseline />
      
        <Switch>
          <Route exact path="/" component={StartPage} />
          <Route exact path="/kirjaudu" component={LoginModal} />
          <Route exact path="/rekisteroidy" component={RegisterModal} />
          <Route exact path="/uusissana" component={RenewPWDModal} />
          <Route exact path="/paikkavaraus" component={ReservationPage} />
          <Route path="/paikkavaraus/:view" component={ReservationPage} />
          <Route path="/admin" component={ReservationPage} />
          <Route exact path="/markkinainfo" component={MarkkinaInfoPage} />
        </Switch>
        
        </ThemeProviderV5>
      </ThemeProviderV4>
      {
        //</MuiThemeProvider>
      }
        
        </BrowserRouter>
      

      {/* !userMode || userMode === 'null' || userMode === '' ? (
        <StartPage />)
        : (<AppNavbar />
        ) */}
        </StylesProvider>
    </Provider >

  );
}

export { App as default };
