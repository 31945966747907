import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";
import Messages from '../Messages';





const useStyles = makeStyles((theme) => ({
  root: {
    //height: '100vh',
    //overflowY: "scroll",
    //margin: '150 10 10 10',
    backgroundColor: "#FFF",
    //marginTop: theme.spacing(3),
    width: '50vw',
    //justify: 'center',
    //alignItems: 'center',
  },


  modalHeader: {
    textAlign: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    //width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 3, 2),
  },
  footer: {
    color: '#FFF',
    //position: 'fixed',
    //top: '80vh',
    bottom: 0,
    overflow: 'hidden',
    //display: "fixed",
    //width: "100%",
    //top: 0,
    //padding: 0,
    //minWidth: '100vw',
    textAlign: 'center',
    //verticalAlign: 'middle',
    backgroundColor: 'rgba(51, 170, 51, .4)',
  }, footerText: {
  },
  logo: {
    width: '30px',
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: 'none',
  },
  logo2: {
    width: '80px',
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: 'none',
  }
}));

function UserModal(props) {  
  const [name, setName] = useState(props.user.name);
  const [email, setEmail] = useState(props.user.email);
  const [phone, setPhone] = useState(props.user.phone);
  const [isAdmin, setIsAdmin] = useState(props.user.isAdmin==='1');
  const [msg, setMsg] = useState(null);

  const classes = useStyles();

  const dispatch = useDispatch();

  function onModalCancel(event) {
    console.log("cancel add user");  
    resetValues();  
    props.handleCloseModal(event, null);
  }

  function resetValues() {

  }

  function onModalOK(event) {
    console.log("Add user");    

    resetValues();
}

  return (
    <div>
      <Modal
        open={props.modalIsOpen}
        //onClose={handleToggle}
        //aria-labelledby="simple-modal-title"
        //aria-describedby="simple-modal-description"
        aria-labelledby="contained-modal-title-vcenter"
        ariaHideApp={false}
        centered
      >
          {/*
        <Grid container component="main" className={classes.root} >
          <CssBaseline />
        */}
          <Container className={classes.root}>
            <div className={classes.paper}>
              <br />
              <img className={classes.logo2} alt="" src={process.env.PUBLIC_URL + "/logo.png"} /> 
              <Typography component="h1" variant="h5" >
                Lisää käyttäjä 
                            </Typography>
              {msg ? <Alert severity="error">{msg}</Alert> : null}
              <Messages/>
              <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>
              <form className={classes.form} noValidate  >
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  value={name}
                  label="Nimi"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  onChange={e => setName(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"                  
                  fullWidth
                  id="phone"
                  label="Puhelin"
                  name="phone"
                  value={phone}
                  autoComplete="phonenumber"                  
                  onChange={e => setPhone(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Sähköposti"
                  name="email"
                  value={email}
                  autoComplete="email"                  
                  onChange={e => setEmail(e.target.value)}
                />
                <FormControlLabel
                  label="Adminoikeudet"
                  control={
                    <Checkbox
                      
                      onChange={e => setIsAdmin(e.target.value)}
                      name="isAdmin"
                      color="primary"
                      defaultChecked={isAdmin}
                      //checked={isAdmin}
                    />
                  }
                  
                />
               
                <Button
                  //type="submit"                  
                  variant="contained"
                  color="primary"
                  onClick={e => onModalOK(e)}
                  className={classes.submit}
                >
                  Tallenna
            </Button>
            
             
            
              </form> 
              </Box>
              
              

                <Container>
                <Button
                  //type="button"                  
                  variant="contained"
                  color="secondary"
                  onClick={e => onModalCancel(e)}
                  
                >
                  Sulje
            </Button>
               
            </Container>
             
        
            </div>

          </Container>
        {
        //</Grid>
        }

      </Modal>

    </div >
  );
};

export default  UserModal;

