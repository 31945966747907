import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme) => ({

  grow: {
    flexGrow: "1",
    background: "none",
    width: "100vw",
    position: "fixed",
    top: 0,
  },

  appbar: {
    flexGrow: "",
    //background: "none",
    witdh: "100vw",
    zIndex: 5,
    centerTitle: 'true'
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    flexGrow: 1,
    //display: 'none',
    //[theme.breakpoints.up('sm')]: {
    //  display: 'block',
    //},
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  link: {
    underline: "none",
    marginRight: "10px",
    color: '#FFF'
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

}));



const InfoBar = (auth) => {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar className={classes.appbar} position="static" elevation={4}>
        <Toolbar>
          {/*}
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
        */}

          <Link to="/" className={classes.link} color="inherit" >
            <HomeIcon />
          </Link>
          <Typography className={classes.title} variant="h6" noWrap>
            Laurin Markkinat Mynämäellä 10.8.2024
          </Typography>
        </Toolbar >
      </AppBar >      
    </div >
  );
};

export { InfoBar as default }