import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import marketplaceReducer from './marketplaceReducer';

export default combineReducers({
  admin: adminReducer,
  error: errorReducer,
  auth: authReducer,
  places: marketplaceReducer
});
