import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MediaCard from './MediaCard';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { purple } from '@material-ui/core/colors';



const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw',
        marginTop: 0,
        backgroundImage: 'url(https://www.lcmynamaki.fi/wp-content/uploads/2017/02/Lauri15_ilma5m.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: "center",
    },
    container: {
        minWidth: "100vw",
        minHeight: "100vh",
        //marginTop: "0",
        padding: 0,
        align: "center",
    },
    headingBackGroud: {
        minWidth: "100vw",
        //minHeight: "100vh",
        //marginTop: "5vh",
        //marginTop: "10vh",
        paggind: 0,
        marginBottom: "5vh",
        backgroundColor: 'rgba(51, 170, 51, .4)',
    },
    imageText: {
        color: '#FFF',
        textAlign: "center",
    },
    test1: {
        position: 'absolute',
        //left: '50%',
        //marginTop: "30vh",
        backgroundColor: 'none',
        //transform: 'translate(-50%, 0%)'
    },
    button: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        },
    },
    footer: {
        color: '#FFF',
        position: 'fixed',
        bottom: 0,
        padding: 0,
        minWidth: '100vw',
        textAlign: 'center',
        verticalAlign: 'middle',
        backgroundColor: 'rgba(51, 170, 51, .4)',
    },
    logo: {
        width: '30px',
        marginTop: 5,
        marginBottom: 5,
        marginRight: 5,
        backgroundColor: 'none',
    }
}));



const StartPage = () => {

    const classes = useStyles();

    const user = useSelector(state => state.auth.user);

    const visitor = ['Markkinainfo', 'Myyjät / myyntipaikat', 'Tarjoukset'];
    const seller = ['Paikkavaraukset', 'Yhteystiedot', 'Ilmoita markkinatarjous'];

    return (
        <Grid className={classes.root}>
            <Container className={classes.container}>
                <Container className={classes.headingBackGroud} disableGutters>
                    <Typography className={classes.imageText} variant="h3">
                        Laurin Markkinat
                    </Typography>
                    <Typography className={classes.imageText} variant="h6">
                        Mynämäellä lauantaina 10.8.2024
                    </Typography>
                </Container>
                <Grid container justify="space-evenly" className={classes.test1} >
                    <Grid key='visitor' item xs={6} sm={4} md={4} lg={3} xl={2}>
                        <Container disableGutters>
                            <MediaCard caption="Markkinavieraat" buttonText="Markkinainfo" buttonRoute="/markkinainfo" description={visitor} className={classes.card}>
                            </MediaCard>
                        </Container>

                    </Grid>
                    <Grid key='seller' item xs={6} sm={4} md={4} lg={3} xl={2}>
                        <Container disableGutters>
                            <MediaCard caption="Torimyyjät" user={user} buttonText="Paikkavaraus" buttonRoute="/kirjaudu" description={seller} className={classes.card}>
                            </MediaCard>
                        </Container>
                    </Grid>
                </Grid>

            </Container>
            <Container className={classes.footer}>
                <img className={classes.logo} alt="" src={process.env.PUBLIC_URL + "/icon.gif"} />
                <Typography className={classes.footerText} variant="h6">
                    Lions Club Mynämäki ry
                </Typography>
            </Container>
        </Grid >
    );
}


export { StartPage as default };

