import { makeStyles } from '@material-ui/core/styles';
import { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppNavbar from './AppNavbar';
import MapPage from './MapPage';
import UserList from './UserList';
import SpotList from './SpotList';
import { loadUser } from '../flux/actions/authActions';
import Fab from '@material-ui/core/Fab';
import { green } from '@material-ui/core/colors';
import NavigationIcon from '@material-ui/icons/Navigation';
import { Link } from 'react-router-dom';

import { Route, Redirect, Switch, useRouteMatch, useParams } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {

        //display: "flex",
        //flex: "1 1 auto",
        backgroundColor: "#333",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center"

        //marginTop: 0,
        //overflowY: "scroll",
    },
    navbar: {
        position: "fixed",
        overflowX: "hidden",
    },
    mapPage: {
        position: "fixed",
        paddingTop: "260px",
        alignItems: "skrecth",
        //overflowX: "hidden",
        overflowY: "scroll",
    },
    reservationPage: {
        position: "fixed",
        //paddingTop: "260px",
        //alignItems: "skrecth",
        //overflowX: "hidden",
        //overflowY: "scroll",
    },
    fab: {
        position: 'absolute',
        top: theme.spacing(15),
        right: theme.spacing(10),
    },
    fabGreen: {
        position: 'absolute',
        top: theme.spacing(15),
        right: theme.spacing(10),
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
}));

/*
const initialState = {
    valitut: [],
    poistetut: [],
    hinta: 0,
    viimeksiKoskettu: undefined
};
*/



const ReservationPage = () => {


    const { path, url } = useRouteMatch();
    var { view } = useParams();
    const classes = useStyles();

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const token = useSelector(state => state.auth.token); //localStorage.getItem("token");
    const varaukset = useSelector(state => state.places.reservations);

    //const search = useLocation().search;
    //var view = "";//new URLSearchParams(search).get('view');
    if(!view || view === "") {
       view = 'kartta';
    }

    console.log("URL: " + url);
    console.log("PATH: " + path);
    console.log("PARAM: " + view);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!token || !isAuthenticated) {
            dispatch(loadUser);
        }
    });

    let showReservationButton = (view) => {
        if (view === "kartta" || view === "lista") {            
            if (varaukset.selected && varaukset.selected.length > 0) {
                return (<Fab variant="extended" component={Link} to="/paikkavaraus?view=varaus" size="medium" aria-label="add" className={classes.fabGreen}>
                    <NavigationIcon className={classes.extendedIcon} />
                    Varaa
                </Fab>);
            } else {
                return null;
            }            
        }
    }

    return (
        !isAuthenticated ? <Redirect to="/kirjaudu"/> :

        <div className={classes.root}>

            <AppNavbar className={classes.navbar}></AppNavbar>

            {/* 
            <LoginModal></LoginModal>
            <MapPage className={classes.mapPage}></MapPage> 
            {renderSwitch(view)}
            <BrowserRouter basename="/paikkavaraus">
            */}
            
                <Switch>
                    <Route exact path="/paikkavaraus"><MapPage className={classes.mapPage}></MapPage></Route>
                    <Route path="/paikkavaraus/kartta"><MapPage className={classes.mapPage}></MapPage></Route>
                    <Route path="/paikkavaraus/lista"><SpotList className={classes.mapPage}></SpotList></Route>
                    <Route path="/admin/varaukset"><SpotList className={classes.mapPage}></SpotList></Route>
                    <Route path="/admin/kayttajat"><UserList className={classes.mapPage}></UserList></Route>
                   
                    {/*
                     <Route path="/lista2" component={SpotList} />
          <Route path="`${match.url}/kartta`"><MapPage className={classes.mapPage}></MapPage></Route>
          <Route path="`${match.url}/kartta`" component="{MapPage}"/>
          <Route path="lista"><SpotList className={classes.mapPage}></SpotList></Route>
          <Route path="*"><MapPage className={classes.mapPage}></MapPage></Route>
          </BrowserRouter>
        */}
                </Switch>
            


            {(varaukset && varaukset.selected.length > 0) ?
                showReservationButton(view) :
                null
            }
        </div>        
    );
}


export { ReservationPage as default };

