import * as actions from "../actions/types";

const initialState = {
    isLoading: false,
    places: [],
    reservations: {
        selected: [],
        removed: [],
        price: 0
    }

    /*
        paikkaNumero: null,
        koko: null,
        hinta: null,
        koordinaatit: [],
        tila: null,
        myyjänID: null
    */

};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.MAP_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case actions.MAP_LOADED:
            const newState = {
                ...state,
                isLoading: false,
                places: action.payload
            };
            return newState;
        case actions.ADD_RESERVATION:
            //return state;
            return {
                ...state,
                reservations: {
                    selected: [...state.reservations.selected, action.payload.getName()],
                    price: state.reservations.price + parseInt(action.payload.attrs['hinta'])
                }
            };           
        case actions.REMOVE_RESERVATION:
                return {
                    ...state,
                    reservations: {
                        selected: state.reservations.selected.filter(item => item !== action.payload.getName()),
                        price: state.reservations.price - parseInt(action.payload.attrs['hinta'])
                    }
                };     
        case actions.CLEAR_RESERVATIONS:        
                return {
                    ...state,
                    reservations: {
                        selected: [],
                        removed: [],
                        price: 0
                    }
                };                  
        default:
            return state;
    }
}
