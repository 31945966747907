//import { useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MapPageInfo from './MapPageInfo';
import InfoBar from './InfoBar';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flex: "1 1 auto",
        //position: "relative",
        //height: '100vh',
        //width: '100vw',
        //marginTop: 0,
        //backgroundImage: 'url(https://www.lcmynamaki.fi/wp-content/kartta.svg)',
        //backgroundRepeat: 'no-repeat',
        //backgroundSize: 'cover',
        //backgroundPosition: "center",
        backgroundColor: "#333",
        marginTop: "60px",
    },
    mapPage: {
        position: "fixed",
        paddingTop: "260px",
        alignItems: "skrecth",
        //overflowX: "hidden",
        overflowY: "scroll",
    },
    container: {
        display: "flex",
        flex: "1 1 auto",
        //position: "initial",
        //minWidth: "100vw",
        height: "100vh",
        //marginTop: "0",
        //padding: 0,    
        align: "center",
        color: "#FFF",
        justifyContent: "center",
        alignItems: "center",
    },
    hidden: {
        display: 'none',
    },
}));


const MarkkinaInfoPage = () => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <InfoBar className={classes.navbar} />            
            <MapPageInfo className={classes.mapPage}></MapPageInfo>
            
        </div>
    );
}



export { MarkkinaInfoPage as default };

