import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Messages from './Messages';
import AddUserModal from './companies/AddUserModal';
import ShowModal from './companies/ShowModal';
import { loadAllUsers } from '../flux/actions/adminActions';
import { DataGrid } from '@mui/x-data-grid';
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        //witdh: '100vw',
        //padding: '20px',
        //margin: '150 10 10 10',
        //overflowY: "scroll",
        //flex: "1 1 auto",
        display: "flex",
        //alignSelf:'stretch',
        justify: 'center',
        alignItems: 'center',
        //backgroundColor: "#FFF",
    },
    paper: {
        //margin: theme.spacing(10,20,20,20),  
        //marginTop: '20',      
        //padding: '5px',
        //witdh: '70%',
        //display: 'flex:1',
        //flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#FFF"
    },
    form: {
        //width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(5),
    },
    modal: { flexDirection: "row" ,marginLeft: 20, justifyContent: 'space-evenly' },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));






const UserList = () => {

    //const [rerender,setRerender] = useState(1);
    const [modalIsOpen, setIsOpen] = useState(false);    
    const [email, setEmail] = useState("");

    const users = useSelector(state => state.admin.users)

    const classes = useStyles();
    const dispatch = useDispatch();


    function openFromParent() {
        setIsOpen(true);
    }

    function handleAddUserModalCancel(event, data) {
        console.log(data);        
        //setIsOpen(false);           
        //setEmail(data);
    }

    function handleAddUserModalSubmit(event, data) {
        console.log(data);        
        //setIsOpen(false);           
        setEmail(data);
    }

    function handleCancelUserModal(event, data) {
        console.log("User modal closed");      
        //setRerender(rerender + 1);
    }

    function handleSubmitUserModal(event, data) {
        console.log("User modal submitted");                  
        //setRerender(rerender + 1);
    }

    function getUser(email) {    
        const user =  users.list.filter((aUser) => aUser.email === email);  
        return user[0];
    }

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'userID',
            headerName: 'UserID',
            width: 120,
            editable: false,
            hide: true
          },
        {
          field: 'name',
          headerName: 'Nimi',
          width: 200,
          editable: true,
        },        
        {
          field: 'email',
          headerName: 'Sähköposti',    
          width: 250,
          editable: true,
        },
        {
          field: 'phone',
          headerName: 'Puhelin',
          //type: 'phonenumber',
          width: 150,
          editable: true,
        },,
        {
          field: '',
          headerName: 'Avaa',
          renderCell: (params) => (
            <ShowModal children="UserModal" buttonName="Näytä" user={getUser(params.getValue(params.id, 'email'))} onCloseModalSubmit={handleSubmitUserModal} onCloseModalCancel={handleCancelUserModal}/>
          ),
        }
        /*
        {
          field: 'fullName',
          headerName: 'Full name',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 160,
          valueGetter: (params) =>
            `${params.getValue(params.id, 'firstName') || ''} ${
              params.getValue(params.id, 'lastName') || ''
            }`,
        },
        */
      ];    

    useEffect(() => {
        if(!users || ((!users.list || users.list.length === 0) && users.isLoading === false)) {            
            loadAllUsers(dispatch);            
        }
      },[users]);


    return (

        <div className={classes.root}>
            {
                (!users || !users.list || users.isLoading) ?
                    <Typography component="h1" variant="h5" className={classes.modalHeader}>
                        Loading
                    </Typography> :
                    (
                        <Container className={classes.paper}>
                            {
                            <Typography component="h1" variant="h5" className={classes.modalHeader}>
                                Rekisteröityneet käyttäjät
                                <hr />
                            </Typography>
                            }
                            <Messages />
                            {
                                //error.msg ? <Alert severity="error">{error.msg}</Alert> : null
                                //<Button variant="contained" color="primary" startIcon={<PersonAddIcon />} onClick={openFromParent}>Lisää käyttäjä</Button>
                            }

                         
                            <ShowModal classes={classes.modal} children="AddUserModal" buttonName="Lisää käyttäjä" startIcon={<PersonAddIcon />} email="tommi.lautanala" onCloseModalSubmit={handleAddUserModalSubmit} onCloseModalCancel={handleAddUserModalCancel}/>                                                      

                            <div style={{  width: '100%' }}>                            
                            <DataGrid
                                rows={users.list}
                                autoHeight
                                autoPageSize={true}
                                columns={columns}
                                pageSize={10}                                
                                rowsPerPageOptions={[10]}
                                //checkboxSelection
                                //disableSelectionOnClick
                            />    
                            </div>                                                                                                                    

                            {/*            
                            <AddUserModal                                 
                                IsModalOpened={modalIsOpen}                                
                                onCloseModal={handleCloseModal}
                                dataGridId={users.list.length + 1}
                            /> 
                        */}

                        </Container>
                    )
            }
        </div>
    );
};

export { UserList as default };