import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from "react-router-dom";
import { clearErrors } from '../../flux/actions/errorActions';

import { login } from '../../flux/actions/authActions';

let history;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" >
      { 'Copyright © '}
      < Link color="inherit" href="https://material-ui.com/" >
        Lions Club Mynämäki ry -
                    </Link>{' '}
      { new Date().getFullYear()}
      { '.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflowY: "scroll",
  },
  image: {
    backgroundImage: 'url(https://www.lcmynamaki.fi/wp-content/uploads/2017/02/Lauri15_ilma5m.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageText: {
    //color: '#E9E969',
    backgroundColor: 'rgba(51, 170, 51, .4)',
    color: '#FFF',
    textAlign: "center",
  },
  modalHeader: {
    textAlign: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  footer: {
    color: '#FFF',
    //position: 'fixed',
    //top: '80vh',
    bottom: 0,
    overflow: 'hidden',
    //display: "fixed",
    //width: "100%",
    //top: 0,
    //padding: 0,
    //minWidth: '100vw',
    textAlign: 'center',
    //verticalAlign: 'middle',
    backgroundColor: 'rgba(51, 170, 51, .4)',
  }, footerText: {
  },
  logo: {
    width: '50px',
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: 'none',
  },
  logo2: {
    width: '80px',
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: 'none',
  }
}));

const LoginModal = () => {
  const [modal, setModal] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState(null);

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const isRegistered = useSelector(state => state.auth.isRegistered)
  const isRenewed = useSelector(state => state.auth.isRenewed)
  const error = useSelector(state => state.error);

  const classes = useStyles();

  history = useHistory();

  const dispatch = useDispatch();

  const handleToggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  /*
  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);
  */

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const user = { email, password };

    // Attempt to login
    //login(dispatch,user);
    dispatch(clearErrors());
    dispatch(login(user));
  };

  useEffect(() => {
    // Check for register error

    //dispatch(loadUser());

    if (error.id === 'LOGIN_FAIL') {
      setMsg(error.msg);
    } else {
      setMsg(null);
    }

    // If authenticated, close modal
    if (modal) {
      if (isAuthenticated) {
        handleToggle();
        history.push("/paikkavaraus");
      }
    }
  }, [error, handleToggle, isAuthenticated,isRegistered,isRenewed, modal]);

  return (
    <div>

      {/*
      <Typography className={classes.root}>
        <Link href="#" onClick={handleToggle}>
          Login
      </Link>
      </Typography>
      */}

      <Modal
        open={modal}
        onClose={handleToggle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid container component="main" className={classes.root} >
          <CssBaseline />
          <Hidden only={['sm', 'xs']}>


            <Grid item md={9} className={classes.image} >
              <Container className={classes.headingBackGroud} disableGutters>
                <Typography className={classes.imageText} variant="h3">
                  Laurin Markkinat
                    </Typography>
                <Typography className={classes.imageText} variant="h6">
                  Mynämäellä lauantaina 10.8.2024
                    </Typography>
              </Container>
              {/*
              <Box className={classes.imageText}>
                <Typography component="h1" variant="h5">
                  <p><h1>Laurin Markkinat </h1>
                    <h2> Mynämäellä </h2>
                    <h2> lauantaina 7.8.2021 </h2>
                  </p>
                </Typography>
              </Box>
              */}
              <Container className={classes.footer} disableGutters>
                <Typography className={classes.footerText} variant="h6">
                  Järjestäjä
                </Typography>
                <img className={classes.logo} alt="" src={process.env.PUBLIC_URL + "/icon.gif"} />                
                <Typography className={classes.footerText} variant="h6">
                  Lions Club Mynämäki ry
                </Typography>
              </Container>
            </Grid>

          </Hidden>
          <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square >
            <div className={classes.paper}>
              <Typography component="h1" variant="h5" className={classes.modalHeader}>
                Laurin Markkinoiden Paikkavaraus
                            <hr />
              </Typography>
              <br />
              {/*
              <Avatar className={classes.avatar} >
              <LockOutlinedIcon />                             
              </Avatar>
              */}
              <img className={classes.logo2} alt="" src={process.env.PUBLIC_URL + "/logo.png"} /> 
              <Typography component="h1" variant="h5" >
                Kirjaudu
                            </Typography>
              {msg ? <Alert severity="error">{msg}</Alert> : null}
              {isRegistered ? <Alert severity="info">Rekisteröinti onnistui. Ole hyvä ja kirjaudu.</Alert> : null}
              {isRenewed ? <Alert severity="info">Uusi salasana lähetettiin antamaasi osoitteeseen.</Alert> : null}
              <form className={classes.form} noValidate onSubmit={handleOnSubmit} >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Sähköposti"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Salasana"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={e => setPassword(e.target.value)}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Muista minut"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Kirjaudu
            </Button>
                <Grid container direction="row" justify="space-evenly" alignItems="center">
                  <Grid item xs={2}>
                    <Link href="/" variant="body2" >
                      <HomeIcon></HomeIcon>
                    </Link>
                  </Grid>
                  <Grid item xs={2}>
                    <Link href="/uusissana" variant="body2" >Salasana hukassa?</Link>
                  </Grid>
                  <Grid item xs={2}>
                    <Link href="/rekisteroidy" variant="body2" >Rekisteröidy</Link>
                  </Grid>
                </Grid>
                < Box mt={5} >
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export { LoginModal as default };