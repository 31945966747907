import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        minWidth: 200,
        display: "flex",
        flexDirection: "column",
        backgroundColor: 'rgba(250, 250, 250, .7)',
        marginLeft: 5,
        marginRight: 5,
    },
    media: {
        minHeight: 340,
    },
    button: {
        marginBottom: 10,
    }
});



export default function MediaCard({ caption, buttonText, buttonRoute, description, user }) {
    const classes = useStyles();

    const test = description ? description.map((value) => <p key={value}>- {value}</p>) : 'Nothing';

    console.log(test);

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography key="caption" gutterBottom variant="h5" component="h2">
                    {caption}
                    {user ? user.name : ""}
                </Typography>

                <Typography key="content" variant="h3" color="primary">
                    {test}
                </Typography>
            </CardContent>

            <Link to={buttonRoute} variant="body2" >
                <Container>
                    <Button className={classes.button} variant="contained" fontSize="large" color="primary">
                        {buttonText}
                    </Button>
                </Container>
            </Link>
        </Card >
    );
}