import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Konva from 'konva';
import { loadMap, addReservation, removeReservation } from '../flux/actions/marketplaceActions';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { green } from '@material-ui/core/colors';



const useStyles = makeStyles((theme) => ({
    root: {
        //position: "relative",
        height: '100vh',
        width: '100vw',
        //marginTop: 0,
        //backgroundImage: 'url(https://www.lcmynamaki.fi/wp-content/kartta.svg)',
        //backgroundRepeat: 'no-repeat',
        //backgroundSize: 'cover',
        //backgroundPosition: "center",
        //backgroundColor: "#333",
        //marginTop: "75px",
        //overflowY: "scroll",
    },
    mapContainer: {
        position: "fixed",
        backgroundColor: "#333",
        //paddingTop: "64px",
        // marginTop: "64px",
        //height: "100vh - 64px",
        width: '100vw',
    },
    canvas: {
        //position: "relative",
        // margintop: '100px', 
        height: '100vh - 100px',
        width: '100vw',
        zIndex: "-1",
    },
    info: {
        position: "fixed",
        top: 80,
        left: 10,
        border: "2px solid green",
        borderRadius: "5px",
        zIndex: '4',

        backgroundColor: 'rgba(250, 250, 250, .5)',
    },
    infoText: {
        position: "relative",
        padding: '5px',
    },
    fab: {
        position: 'absolute',
        top: theme.spacing(15),
        right: theme.spacing(10),
      },
      fabGreen: {
        position: 'absolute',
        top: theme.spacing(15),
        right: theme.spacing(10),          
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[600],
        },
      },
    hidden: {
        display: 'none',
    },
}));

var width = window.innerWidth;
var height = window.innerHeight;
var scaleMultiplier = 0.8;
var lastDist = 0;

const COLOUR_FREE = "#66ff00";
const COLOUR_PRERESERVED = "orange";
const COLOUR_RESERVED = "red";
const COLOUR_PAYED = "blue";
const COLOUR_OWN = "#40E0D0";

var stage = null;
//resizeCanvas();
var layer;
var imageObj;
var layer2;
//var textLayer;

/*

*/

let isAuthenticated;
let history;
//let selectedSpot;
let infoText;



//let handleClickOpen = null;
//let handleClose = null;

//let setElectricity230 = null;
//let setElectricity400 = null;

//let is230V = null;
//let is400V = null;

const emails = ['username@gmail.com', 'user02@gmail.com'];

const MapPage = () => {

    const classes = useStyles();

    const [selectElectricityDialogOpen, setSelectElectricityDialogOpen] = useState(false);    
    const varaukset= useSelector(state => state.places.reservations);


    /*
    let setVaraukset = (varaukset) => {
        dispatch(updateReservations(varaukset));
        //data.handleVaraukset(varaukset);
        //setUpdate(!update);
    }
    */

    let addToReservation = (place) => {
        dispatch(addReservation(place)); 
    }

    let removeFromReservation = (place) => {
        dispatch(removeReservation(place));   
    }

    //const [size, setSize] = useState('');
    //const [prize, setPrize] = useState('');
    //const [spotID, setSpotID] = useState('');
    //const [e230V, setE230] = useState(false);
    //const [e400V, setE400] = useState(true);

    const handleClose = (value) => {
        setSelectElectricityDialogOpen(false);
        //setSelectedValue(value);
    };

    const handleListItemClick = (value) => {
        setSelectElectricityDialogOpen(value);
    };

    //const imageRef = useRef(null);
    const canvasRef = useRef(null);
    infoText = useRef(null);
    history = useHistory();

    /*
    const is230V = () => {
        return e230V;
    }

    const is400V = () => {
        return e400V;
    }

    const setElectricity230 = () => {
        setE230(!e230V);
    }

    const setElectricity400 = () => {
        setE400(!e400V);
    }
    */

    /*
    handleClickOpen = () => {
        setSelectElectricityDialogOpen(true);
    };
    */


    isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    //user = useSelector(state => state.auth.user);
    const places = useSelector(state => state.places);

    const dispatch = useDispatch();



    useEffect(() => {
        if (!places.isLoading) {
            if ((!places.places || places.places.length <= 0)) {
                loadMap(dispatch);
            } else if (!canvasRef.current.firstChild) {
                stage = getStage(width, height);
                //resizeCanvas();
                layer = new Konva.Layer();
                stage.add(layer);
                imageObj = getLMMapImage();
                layer2 = new Konva.Layer();
                //layer.hide();
                //layer2.zIndex(3);



                const ctx = canvasRef.current.getContext("2d");

                /*var kartta = new Konva.Image({
                    x: 0,
                    y: 0,
                    image: imageRef.current
                })*/

                //ctx.drawImage(imageRef.current, 0, 0);
                ctx.drawImage(imageObj, 0, 0);

                infoText.current.innerHTML = getInfoTable(null);
                var processedSpots = [];
                Array.prototype.forEach.call(places.places, place => {
                    if(!processedSpots.includes(place.spotID)) {
                    // if ((!is230V() && !is400V()) || (place.electric230v === "1" && is230V()) || (place.electric400v === "1" && is400V())) { */
                        addPlace(place.spotID, place.name, place.coordinates, place.status, false, varaukset, place.size, place.prize, place.electric230v, place.electric400v, layer2, COLOUR_FREE, COLOUR_PRERESERVED, COLOUR_RESERVED,COLOUR_PAYED, COLOUR_OWN);
                        processedSpots.push(place.spotID);
                    //}
                    }
                })
                stage.add(layer2);
            }
        } else if (stage) {
            resizeCanvas();
        }
    });

    function selectPlace(aPoly, aLayer, varaukset) {
        if (!isAuthenticated) {
            history.push("/kirjaudu");
        } else {
            if (aPoly.selected) {
                aPoly.setFill(COLOUR_FREE);
                aPoly.selected = false;
                //removeA(varaukset.valitut, aPoly.getName());
                //varaukset.hinta = parseInt(varaukset.hinta) - parseInt(aPoly.attrs['hinta']);
                removeFromReservation(aPoly)
            } else {
                //handleClickOpen(aPoly);                    
                aPoly.setFill(COLOUR_OWN);
                aPoly.selected = true;
                //varaukset.valitut.push(aPoly.getName());
                //varaukset.hinta = parseInt(varaukset.hinta) + parseInt(aPoly.attrs['hinta']);            
                //this.setState({varaukset: varaukset});
                addToReservation(aPoly);
            }
    
            varaukset.selected.sort(comparePaikat);

            //showReservation(varaukset);
            //showReservationMenu(varaukset)
    
            //aLayer.draw();
            //setVaraukset(varaukset);
        }
    }

    function getStage(aWitdth, aHeight) {

        Konva.hitOnDragEnabled = true;
    
        var stage = new Konva.Stage({
            container: 'mapContainer',
            preventDefault: false,
            height: aHeight,
            width: aWitdth,
            scaleX: 1,
            scaleY: 1,
            draggable: true
        });
    
        stage.on('wheel', function (event) {
            var touchPos = stage.getPointerPosition();
            var bx = stage.getX();
            var by = stage.getY();
            var bsx = stage.getScaleX();
            var bsy = stage.getScaleY();
            if (event.evt.deltaY < 0) {
                if (stage.getScaleX() > 10) {
                    return;
                }
                stage.setScaleX(stage.getScaleX() / scaleMultiplier);
                stage.setScaleY(stage.getScaleY() / scaleMultiplier);
                stage.draw();
            } else {
                var newScale = stage.getScaleX() * scaleMultiplier;
    
                if (newScale < stage.minScale) {
                    newScale = stage.minScale;
                }
                stage.setScaleX(newScale);
                stage.setScaleY(newScale);
                stage.draw();
            }
    
            var newX = touchPos.x - stage.getScaleX() * (touchPos.x - bx) / bsx;
            var newY = touchPos.y - stage.getScaleY() * (touchPos.y - by) / bsy;
    
    
            stage.setX(newX);
            stage.setY(newY);
            //relocateCanvas(false); 
            stage.draw();
        });
    
        stage.on("dragend", function(e) {        
            relocateCanvas(true);        
        });
    
        stage.on('touchmove', function (e) {
            e.evt.preventDefault();
    
            var bx = stage.getX();
            var by = stage.getY();
            var bsx = stage.getScaleX();
            var bsy = stage.getScaleY();
    
            var touch1 = e.evt.touches[0];
            var touch2 = e.evt.touches[1];
    
            if (touch1 && touch2) {
                stage.multitouch = true;
    
                var dist = getDistance(
                    {
                        x: touch1.clientX,
                        y: touch1.clientY
                    },
                    {
                        x: touch2.clientX,
                        y: touch2.clientY
                    }
                );
    
                if (!lastDist) {
                    lastDist = dist;
                }
    
                var scale = (stage.scaleX() * dist) / lastDist;
    
                stage.scaleX(scale);
                stage.scaleY(scale);
    
                var tx = (touch1.clientX + touch2.clientX) / 2;
                var ty = (touch1.clientY + touch2.clientY) / 2;
    
    
                var newX = tx - scale * (tx - bx) / bsx;
                var newY = ty - scale * (ty - by) / bsy;
    
                stage.setX(newX);
                stage.setY(newY);
    
                stage.draw();
                lastDist = dist;
            }
        });
    
        stage.on('touchend', function () {
            lastDist = 0;
        });
    
        stage.on('dbltap', function () {
            if (stage.multitouch) {
                stage.multitouch = false;
                return;
            }
            stage.setDraggable(!stage.getDraggable());
    
            /* TODO
            if (stage.getDraggable()) {
                document.getElementById("lukitus").style.visibility = "hidden";
            } else {
                document.getElementById("lukitus").style.visibility = "visible";
            }
            */
        });
    
        window.addEventListener('resize', resizeCanvas, false);
    
        /*
        window.onload = function () {
            // add button event listeners
            document.getElementById("plus").addEventListener("click", function () {
                stage.setScaleX(stage.getScaleX() / scaleMultiplier);
                stage.setScaleY(stage.getScaleY() / scaleMultiplier);
                stage.draw();
            }, false);
        
            document.getElementById("minus").addEventListener("click", function () {
        
                var newScale = stage.getScaleX() * scaleMultiplier;
        
                if (newScale < stage.minScale) {
                    newScale = stage.minScale;
                }
        
                stage.setScaleX(newScale);
                stage.setScaleY(newScale);
                stage.draw();
            }, false);
        
        }
        */
    
        return stage;
    
    }
    
    function getDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }
    
    function resizeCanvas() {
        height = window.innerHeight - 64;
        width = window.innerWidth;
        stage.setWidth(width);
        stage.setHeight(height);
        CenterAndScaleStage();
        stage.draw();
    }
    
    function CenterAndScaleStage() {
        var scale1 = height / imageObj.height;
        var scale2 = width / imageObj.width;
    
        if (scale1 < scale2) {
            stage.setScaleX(scale1);
            stage.setScaleY(scale1);
            stage.setY(0);
    
            stage.setX((width - (imageObj.width) * scale1) / 2);
    
        } else {
            stage.setScaleX(scale2);
            stage.setScaleY(scale2);
            stage.setX(0);
            //stage.setY((height - (imageObj.height) * scale2) / 2);
            stage.setY(0);
        }
        stage.minScale = stage.getScaleX();
    }
    
    function getLMMapImage() {
        var image = new Image();
        image.onload = function () {
            var kartta = new Konva.Image({
                x: 0,
                y: 0,
                image: imageObj
            });
    
            if (imageObj) {
                var scale1 = height / imageObj.height;
                var scale2 = width / imageObj.width;
    
                if (scale1 < scale2) {
                    stage.setScaleX(scale1);
                    stage.setScaleY(scale1);
                    stage.setY(0);
    
                    stage.setX((width - (image.width) * scale1) / 2);
    
                } else {
                    stage.setScaleX(scale2);
                    stage.setScaleY(scale2);
                    stage.setX(0);
                    //stage.setY((height - (image.height) * scale2) / 2);
                    stage.setY(0);
                }
                stage.minScale = stage.getScaleX();
            }
    
    
            // add the shape to the layer
            layer.add(kartta);
            stage.batchDraw();
        };
        //image.src = 'https://www.lcmynamaki.fi/wp-content/kartta.svg';
        image.src = 'https://lauri.lcmynamaki.fi/kartta_2022.svg';
    
        return image;
    }
    
    
    
    
    function comparePaikat(p1, p2) {
    
        var p1_temp = p1.match(/(\d+)/);
        var p2_temp = p2.match(/(\d+)/);
    
        return parseInt(p1_temp) - parseInt(p2_temp);
    
    }

    function relocateCanvas(draw) {
        var stageW = stage.scaleX() * imageObj.width;        
        var stageH = stage.scaleX() * imageObj.height; 
        var update = false;
        if(stage.getX() + stageW <= width && stage.getX() <= 0) {
            if(stageW > width) {
                stage.setX(width-stageW);
            } else {
                stage.setX(0);
            }
            update = true;
        }
        if(stage.getY() + stageH <= height && stage.getY() <= 0) {
            if(stageH > height) {
                stage.setY(height-stageH);
            } else {
                stage.setY(0);
            }
            update = true;
        }
        if(stage.getX() + stageW > width && stage.getX() > 0 ) {
            if(stageW > width) {
                stage.setX(0);
            } else {
                stage.setX(width-stageW);
            }
            update = true;
        }
        if(stage.getY() + stageH > height && stage.getY() > 0) {
            if(stageH > height) {
                stage.setY(0);
            } else {
                stage.setY(height-stageH);
            }
            update = true;
        }
    
        if(update && draw) {
            stage.draw();
        }
    }
    
    
    /*
    function removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }
    */
    
    
    function  addPlace(id, aName, aPoints, status, omaVaraus, varaukset, aKoko, aHinta, elec230, elec400, aLayer, colourFree, colourPreReserved, colourReserved, colourPayed,colourOwn) {
        //function addPlace(aName, aPoints, status, aLayer) {
    
        let coordinates;

        
        if(!omaVaraus && varaukset && varaukset.selected && varaukset.selected.includes(aName)) {
            omaVaraus = true;
        }
        
    
        if (!Array.isArray(aPoints)) {
            let tmp = aPoints.replace("[", "");
            tmp = tmp.replace("]", "");
            coordinates = tmp.split(`,`).map(x => +x);
        } else {
            coordinates = aPoints;
        }
    
        var bgColor = colourFree;
    
        if (status === 'Ennakkovaraus') {
            bgColor = colourPreReserved;
        }
    
        if (status === 'Varattu') {
            bgColor = colourReserved;
        }

        if (status === 'Maksu paikalla') {
            bgColor = colourReserved;
        } 

        if (status === 'Laskutettu') {
            bgColor = colourReserved;
        }        

        if (status === 'Maksettu') {
            bgColor = colourPayed;
        }
    
        if (omaVaraus) {
            bgColor = colourOwn;
        }
    
        var electricity = "Ei";
    
        if (elec230 === "1" && elec400 === "1") {
            electricity = "230V / 400V";
        } else if (elec230 === "1") {
            electricity = "230V";
        } else if (elec400 === "1") {
            electricity = "400V";
        }
    
        var aPoly = new Konva.Line({
            name: aName,
            koko: aKoko,
            hinta: aHinta,
            points: coordinates,
            status: status,
            electricity: electricity,
            fill: bgColor,
            stroke: 'black',
            strokeEnabled: false,
            opacity: 0.5,
            closed: true
        });
    
        if (omaVaraus) {
            aPoly.selected = true;
        }
    
        var aPolyLine = new Konva.Line({
            points: coordinates,
            fill: bgColor,
            stroke: 'black',
            fillEnabled: false,
            strokeWidth: 1,
            //opacity: 0.7,
            closed: true
        });
    
        if (status === 'Vapaa' || omaVaraus) {
            aPoly.on('mouseout', function () {
                //document.getElementById("info").innerHTML = "";
                infoText.current.innerHTML = getInfoTable(null);
                aPolyLine.setStroke('black');
                aLayer.draw();
            });
    
            aPoly.on('mouseover', function () {
                //selectedSpot = aPoly;
                //document.getElementById("infoText").innerHTML = aPoly.getName();
                infoText.current.innerHTML = getInfoTable(aPoly);
                /*
                "<table>" +
                    "<tr><td><strong>Paikka:</strong></td><td>" + aPoly.getName() + "</td></tr>" +
                    "<tr><td><strong>Koko:</strong></td><td>" + aPoly.attrs['koko'] + "</td></tr>" +
                    "<tr><td><strong>Hinta:</strong></td><td>" + aPoly.attrs['hinta'] + " €</td></tr>" +
                    "<tr><td><strong>Tila:</strong></td><td>" + aPoly.attrs['status'] + "</td></tr>" +
                    "</table>";
                    */
                //Paikka: { selectedSpot ? selectedSpot['hinta'] : '' } <br />
                //Koko: <br />
                //Hinta: <br />
                //document.getElementById("info").innerHTML = getInfoTable(aPoly.getName(), aPoly.attrs['koko'], aPoly.attrs['hinta']);
                aPolyLine.moveToTop();
                aPolyLine.setStroke('yellow');
                aLayer.draw();
            });
    
    
            aPoly.on('click', function () {
                selectPlace(aPoly, aLayer, varaukset);
            });
    
            aPoly.on('tap', function () {
                selectPlace(aPoly, aLayer, varaukset);
            });
    
            aPoly.on('touchmove', function () {
                if (typeof (varaukset.viimeksiKoskettu) !== 'undefined') {
                    varaukset.viimeksiKoskettu.fire('touchend');
                }
                varaukset.viimeksiKoskettu = aPoly;
    
                infoText.current.innerHTML = getInfoTable(aPoly);
                //document.getElementById("info").innerHTML = getInfoTable(aPoly.getName(), aPoly.attrs['koko'], aPoly.attrs['hinta']);
                aPolyLine.moveToTop();
                aPolyLine.setStroke('yellow');
                aLayer.draw();
    
            });
    
            aPoly.on('touchend', function () {
                //document.getElementById("info").innerHTML = "";
                infoText.current.innerHTML = getInfoTable(null);
                aPolyLine.setStroke('black');
                aLayer.draw();
            });
    
        }
    
        aLayer.add(aPoly);
        aLayer.add(aPolyLine);
    }
    
    function getInfoTable(spot) {
    
    
    
        var table = "<table>" +
            "<tr><td><strong>Paikka:</strong></td><td>" + (spot ? spot.getName() : "") + "</td></tr>" +
            "<tr><td><strong>Koko:</strong></td><td>" + (spot ? spot.attrs['koko'] : "") + "</td></tr>" +
            "<tr><td><strong>Hinta:</strong></td><td>" + (spot ? spot.attrs['hinta'] + " €" : "") + "</td></tr>" +
            "<tr><td><strong>Tila:</strong></td><td>" + (spot ? spot.attrs['status'] : "") + "</td></tr>" +
            "<tr><td><strong>Sähkö:</strong></td><td>" + (spot ? spot.attrs['electricity'] : "") + "</td></tr>" +
            //"<tr><td><input type='checkbox'  value='230V' " + (is230V() ? "checked" : "") + " onClick='(e) => {setElectricity230();}'>230V</input></td>" +
            //"<td><input type='checkbox'  value='400V' " + (is400V() ? "checked" : "") + ">400V</input></td></tr>" +
            "</table>";
        return table;
    }
    
    /*
    function showReservation(varaukset) {
     
     
        var table = "";
     
        if (parseInt(varaukset.hinta) > 0) {
            table = "<table class='infoTable'>";
            table += "<tr>";
            table += "<th>Valitut</th><th>Hinta</th>";
            table += "</tr>";
            table += "<tr>";
            table += "<td>" + varaukset.selected + "</td><td>" + varaukset.price + "€</td>";
            table += "</tr>";
            table += "</table>";
        }
     
        //document.getElementById("valitutPaikat").innerHTML = table;
     
    }
     
    
    function showHelp(layer) {
        document.getElementById("help").style.visibility = "hidden";
        showMessageWindow("Ohje\n\nMyyntipaikkojen merkinnät\n\n\nVie osoitin myyntipaikan päälle nähdäksesi paikan koko- ja hintatiedot.\n\nKlikkaa haluamaasi vapaata myyntipaikka varataksesi sen ja paina tämän jälkeen 'Tallenna', jotta varauksesi tulee kirjattua.\n\n-hiiren vasen: valitse paikka\n-hiiren rulla: zoomaus", textLayer);
        //layer.show();
        stage.draw();
    }
    
     
    function showMessageWindow(message, layer) {
     
        document.getElementById("help").style.visibility = "hidden";
     
        CenterAndScaleStage()
     
        layer = new Konva.Layer();
        stage.add(layer);
     
        var text = new Konva.Text({
            //x: (window.innerWidth - 300) / 2,
            //y: 60,
            text: message,
            fontSize: 34,
            fontFamily: 'Calibri',
            fill: '#555',
            width: (width / stage.getScaleX()) / 1.2,
            padding: 10,
            align: 'center'
        });
     
        var rect = new Konva.Rect({
            //x: (window.innerWidth - 300) / 2,
            //y: 60,
            stroke: '#555',
            strokeWidth: 5,
            fill: '#ddd',
            width: text.width(),
            opacity: 0.8,
            height: text.height(),
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: 10,
            shadowOffsetY: 10,
            shadowOpacity: 0.2,
            cornerRadius: 10
        });
     
     
     
        var rectVapaa = new Konva.Rect({
            x: rect.getX() + rect.getWidth() / 2 - 160,
            y: rect.getY() + 120,
            stroke: '#555',
            strokeWidth: 2,
            fill: COLOUR_FREE,
            width: 100,
            opacity: 0.8,
            height: 40
        });
     
        var textVapaa = new Konva.Text({
            x: rectVapaa.getX(),
            y: rectVapaa.getY(),
            text: 'Vapaa',
            fontSize: 20,
            fontFamily: 'Calibri',
            fill: 'black',
            width: rectVapaa.getWidth(),
            padding: 10,
            align: 'center'
        });
     
        var rectVarattu = new Konva.Rect({
            x: rectVapaa.getX() + rectVapaa.getWidth() + 10,
            y: rectVapaa.getY(),
            stroke: '#555',
            strokeWidth: 2,
            fill: COLOUR_RESERVED,
            width: rectVapaa.getWidth(),
            opacity: 0.8,
            height: rectVapaa.getHeight()
        });
     
        var textVarattu = new Konva.Text({
            x: rectVarattu.getX(),
            y: rectVarattu.getY(),
            text: 'Varattu',
            fontSize: 20,
            fontFamily: 'Calibri',
            fill: 'black',
            width: rectVapaa.getWidth(),
            padding: 10,
            align: 'center'
        });
     
        var rectOma = new Konva.Rect({
            x: rectVarattu.getX() + rectVarattu.getWidth() + 10,
            y: rectVapaa.getY(),
            stroke: '#555',
            strokeWidth: 2,
            fill: COLOUR_OWN,
            width: rectVapaa.getWidth(),
            opacity: 0.8,
            height: rectVapaa.getHeight()
        });
     
        var textOma = new Konva.Text({
            x: rectOma.getX(),
            y: rectOma.getY(),
            text: 'Oma',
            fontSize: 20,
            fontFamily: 'Calibri',
            fill: 'black',
            width: rectVapaa.getWidth(),
            padding: 10,
            align: 'center'
        });
     
        var button = new Konva.Rect({
            x: rect.getX() + rect.getWidth() - 60,
            y: rect.getY() + 10,
            stroke: '#555',
            strokeWidth: 2,
            fill: '#ff0000',
            width: 50,
            height: 50,
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: 10,
            shadowOffsetY: 10,
            shadowOpacity: 0.2,
            cornerRadius: 4
        });
     
        var closeX = new Konva.Text({
            x: rect.getX() + rect.getWidth() - 55,
            y: rect.getY() + 15,
            text: 'X',
            fontSize: 40,
            fontFamily: 'Calibri',
            fill: '#ffffff',
            width: 40,
            height: 40,
            //padding: 40,
            align: 'center',
            listening: false
        });
     
        closeX.fontStyle('bold');
     
        button.moveToTop();
        closeX.moveToTop();
     
        button.on('click', function () {
            document.getElementById("help").style.visibility = "visible";
            //layer.hide();
            layer.destroy();
            stage.draw();
        });
     
        button.on('tap', function () {
            document.getElementById("help").style.visibility = "visible";
            //layer.hide();
            layer.destroy();
            stage.draw();
        });
     
     
        button.on('mouseout', function () {
            button.setStroke('black');
            //closeX.setFill('white'),
                layer.draw();
        });
     
     
     
        button.on('mouseover', function () {
            button.setStroke('white');
            //closeX.setFill('black'),
                layer.draw();
        });
     
     
     
        var group = new Konva.Group({
            x: ((width / stage.getScaleX()) - text.width()) / 2 - stage.getX() / stage.getScaleX(),
            y: 40,
            //width: stage.getWidth() / 2,
            draggable: true,
            align: 'center'
        });
     
     
     
        group.add(rect);
        group.add(text);
        group.add(button);
        group.add(closeX);
        group.add(rectVapaa);
        group.add(textVapaa);
        group.add(rectVarattu);
        group.add(textVarattu);
        group.add(rectOma);
        group.add(textOma);
        layer.add(group);
     
        //layer.draw();
     
        //alert(width + " - " + group.getWidth() + " - " + stage.getScaleX());
    }
    */    

    return (
        <div className={classes.root}>
            <div className={classes.info}>
                <Typography ref={infoText} className={classes.infoText} key="caption" gutterBottom variant="body2">
                </Typography>
                {/*
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        value="e230V"
                        control={<Checkbox color="primary" checked={e230V} onClick={setElectricity230} />}
                        label="230V"
                        labelPlacement="end"
                    />
                    <br />
                    <FormControlLabel
                        value="e400V"
                        control={<Checkbox color="primary" checked={e400V} onClick={setElectricity400} />}
                        label="400V"
                        labelPlacement="end"
                    />
                </FormGroup>
                */}



            </div>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={selectElectricityDialogOpen}>
                <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>
                <List>
                    {emails.map((email) => (
                        <ListItem button onClick={() => handleListItemClick(email)} key={email}>
                            <ListItemText primary={email} />
                        </ListItem>
                    ))}

                    <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
                        <ListItemText primary="Add account" />
                    </ListItem>
                </List>
            </Dialog>
            <div id="mapContainer" className={classes.mapContainer}>

                <canvas ref={canvasRef} className={classes.canvas} />
                {/* <img ref={imageRef} src='https://www.lcmynamaki.fi/wp-content/kartta.svg' className={classes.hidden} /> */}
            </div >
            
        </div >
    );

}



export { MapPage as default };

