import axios from 'axios';
import { tokenConfig } from './tokenUtils';
import {
    MAP_LOADED,
    MAP_LOADING,
    ADD_RESERVATION,
    REMOVE_RESERVATION,
    CLEAR_RESERVATIONS, 
    EDIT_RESERVATION
} from './types';

// Check token & load user
export const loadMap = (dispatch)  => {
    // User loading
    dispatch({ type: MAP_LOADING });

    axios
        .get('https://lauri.lcmynamaki.fi/spots?activityID=3')
        //.get('/spots')
        .then(res =>
            dispatch({
                type: MAP_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            console.log(err);
            //dispatch(returnErrors(err.response.data, err.response.status));
            //dispatch({
            //    type: AUTH_ERROR
            //});
        });
};

export const addReservation = (place) => {
    return ({ type: ADD_RESERVATION, payload: place});   
}

export const removeReservation = (place) => {
    return ({ type: REMOVE_RESERVATION, payload: place});   
}

export const editReservation = (place) => {
    return ({ type: EDIT_RESERVATION, payload: place});   
}

export const clearReservations = () => {
    return ({ type: CLEAR_RESERVATIONS});   
}

// Check token & load user
export const editMyReservation = (dispatch)  => {
     
    // Request body
    const body = JSON.stringify({ action: "Login" });

    axios
        .post('https://lauri.lcmynamaki.fi/admin/editReservation', body, tokenConfig())        
        .then(res =>
            dispatch({
                type: EDIT_RESERVATION,
                payload: res.data
            })
        )
        .catch(err => {
            console.log(err);
            //dispatch(returnErrors(err.response.data, err.response.status));
            //dispatch({
            //    type: AUTH_ERROR
            //});
        });
};