import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';


const Messages = () => {

    const [msg, setMsg] = useState(null);
    const error = useSelector(state => state.error);



    useEffect(() => {
        if (error.id) {
            setMsg(error.msg);
        } else {
            setMsg(null);
        }


    }, [error]);

    return (
        <>
            {
                msg ? <Alert severity="error">{msg}</Alert> : null
            }
        </>
    );
};

export { Messages as default };