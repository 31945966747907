import axios from 'axios';
import { returnErrors } from './errorActions';
import { tokenConfig } from './tokenUtils';

import {
  USERS_LOADED,
  USERS_LOADING,
  AUTH_ERROR,
  USER_ADDED,
  ADD_USER_FAILS, 
  ADDING_USER,
  USER_COMPANIES_LOADING,
  USER_COMPANIES_FAILED,
  USER_COMPANIES_LOADED

} from '../actions/types';

// Check token & load user
export const loadAllUsers = (dispatch) => {
  // User loading
  dispatch({ type: USERS_LOADING });

  axios
    .post('https://lauri.lcmynamaki.fi/admin/GetUsers', {}, tokenConfig())
    .then(res =>
      dispatch({
        type: USERS_LOADED,
        payload: res.data
      })
    )
    .catch(err => {
      var code = err.response.status;
      if (err.response.data) {
        dispatch(returnErrors(err.response.data, err.response.status, 'AUTH_ERROR'));
        code = err.response.status;
      } else {
        dispatch(returnErrors("Odottomaton virhe: " + err.response.statusText, err.response.status, 'AUTH_ERROR'));
      }
      if (code === 401) {
        dispatch({ type: AUTH_ERROR });
      }
    });
};


/************************************************************************** */
// AddUser
/************************************************************************** */

export const addUser = (dispatch, { name, phone, email, isAdmin }, dataGridId) => {


  // Request body
  const body = JSON.stringify({ name, phone, email, isAdmin });

  /*
  axios
    .post('https://lauri.lcmynamaki.fi/admin/AddUser', body, tokenConfig())
    .then(res =>
      dispatch({
        type: USER_ADDED,
        payload: res.data
      })
    )
    .catch(err => {      
      console.log(err);   
      if (err.response) {   
        dispatch(returnErrors(err.response.data, err.response.status, 'USER_ADD_FAILS'));
      } else { 
        dispatch(returnErrors("Odottomaton virhe: " + err.message, 404, 'USER_ADD_FAILS'));  
      }
      dispatch({
        type: ADD_USER_FAILS
      });
    });*/

    dispatch({ type: ADDING_USER});
  
    axios.post('https://lauri.lcmynamaki.fi/admin/AddUser', body, tokenConfig())

    .then(res => {
    res.data.id = dataGridId;
    dispatch({ type: USER_ADDED, payload: res.data })
    return true; 
  } )
  .catch (err => {
    console.log(err);
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status, 'USER_ADD_FAILS'));
    } else {
      dispatch(returnErrors("Odottomaton virhe: " + err.message, 404, 'USER_ADD_FAILS'));
    }
    dispatch({ type: ADD_USER_FAILS });
    return false;
  });
};

export const getUserCompanies = () => (dispatch, userID) => {
  // User loading
  dispatch({ type: USER_COMPANIES_LOADING });

  var headers = tokenConfig();

  //console.log(headers);
  axios
  //.post('https://lauri.lcmynamaki.fi/headers', {}, headers)
  .post('https://lauri.lcmynamaki.fi/getUserCompanies', {userID}, headers)
  //.post('https://lauri.lcmynamaki.fi/login?action=myComp', {}, headers )
    .then(res => {
        console.log("GET USER COMPANIES");
        console.log(res.data);        
        dispatch({type: USER_COMPANIES_LOADED,id: userID, payload: res.data});
      }
    )
    .catch(err => {
      var errType = USER_COMPANIES_FAILED;
      if (err.response) {
        dispatch(returnErrors(err.response.data.msg, err.response.status, 'USER_COMPANIES_FAILED'));
        if(err.response.status === 401) {
          errType = AUTH_ERROR;
        }
      } 
      else if (err.data) {
        dispatch(returnErrors(err.data.msg, err.data.htmlCode, 'USER_COMPANIES_FAILED'));
      }
      else {
        dispatch(returnErrors("Odottomaton virhe: " + err.msg, 404, 'USER_COMPANIES_FAILED'));
      }
      
      dispatch({ type: errType });        
    });
};