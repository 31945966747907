import { IUser } from '../../types/interfaces';
import {
    USERS_LOADED,
    USERS_LOADING,
    USER_ADDED,
    ADD_USER_FAILS,
    ADDING_USER,
    USER_COMPANIES_LOADING,
    USER_COMPANIES_FAILED,
    USER_COMPANIES_LOADED
  } from '../actions/types';
  
  const initialState = {
    users: {
        isLoading: false,        
        status: '',
        list: []
    }
  };

  export default function reducer(state = initialState, action: any) {

    switch (action.type) {
      case USERS_LOADING:
        return {
          ...state,
          users: {
              isLoading: true,
              status: ''
          }
        };
      case USERS_LOADED:
        return {
          ...state,
          users: {            
              isLoading: false,
              status: '',
              list: action.payload

          }         
        };
        case USER_ADDED:
            return {
              ...state,
              users: {
                  isLoading: false,
                  status: USER_ADDED,
                  list: [                    
                    ...state.users.list,
                    action.payload],                      
              }         
            };
        case ADD_USER_FAILS:
            return {
                ...state,
                users: {
                    status: ADD_USER_FAILS,
                    isLoading: false,     
                    list: [...state.users.list]               
                }         
            };
            case ADDING_USER:
              return {...state,
                users: {
                  isLoading: false,
                  status: ADDING_USER,
                  list: [...state.users.list]
                }
              }
              case USER_COMPANIES_LOADING:
                return {
                  ...state,
                  users: {
                    isLoading: true,
                    status: USER_COMPANIES_LOADING,
                    list: [...state.users.list]                    
                  }
                  
                };
              case USER_COMPANIES_LOADED:
                return {
                  ...state,
                  users: {
                      status: USER_COMPANIES_LOADED,
                      isLoading: false,     
                      list: state.users.list.map((user: IUser) => (user.userID === action.id ? {...user, companies: action.payload} : user))
                  }         
              };
                case USER_COMPANIES_FAILED:
                  return {
                    ...state,
                    users: {
                        status: USER_COMPANIES_FAILED,
                        isLoading: false,     
                        list: [...state.users.list]               
                    }         
                };
      default:
        return state;
    }
  }