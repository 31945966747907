
import store from '../store';

// Setup config/headers and token
export const tokenConfig = () => {
    // Get token from localstorage
    
    const token = store.getState().auth.token;
    //useSelector(state => state.auth.token); 
    //const token = localStorage.getItem('token');
    //token = getToken();
    // ;
  
    //console.log("TOKEN=" + token);
  
    // Headers
    const headers = {
      headers: {    
        'Content-type': 'application/json;charset=utf-8'    
    }};
  
    // If token, add to headers
    if (token) {
      headers.headers['X-Auth-Token'] = token;
    }
  
    return headers;
  };