import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Konva from 'konva';
import { loadMap } from '../flux/actions/marketplaceActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


const useStyles = makeStyles((theme) => ({
    root: {
        //position: "relative",
        height: '100',
        width: '100vw',
        //marginTop: 0,
        //backgroundImage: 'url(/kartta_2022.svg)',
        //backgroundRepeat: 'no-repeat',
        //backgroundSize: 'cover',
        //backgroundPosition: "center",
        //backgroundColor: "#333",
        //marginTop: "75px",
        //overflowY: "scroll",
    },
    mapContainer: {
        position: "fixed",
        backgroundColor: "#333",
        //paddingTop: "64px",
        // marginTop: "64px",
        //height: "100vh - 64px",
        width: '100vw',
    },
    canvas: {
        //position: "relative",
        // margintop: '100px', 
        height: '100vh - 100px',
        width: '100vw',
        zIndex: "-1",
    },
    info: {
        position: "fixed",
        top: 80,
        left: 10,
        border: "2px solid green",
        borderRadius: "5px",
        zIndex: '4',

        backgroundColor: 'rgba(250, 250, 250, .5)',
    },
    hidden: {
        display: 'none',
    },
}));

var width = window.innerWidth;
var height = window.innerHeight;
var scaleMultiplier = 0.8;
var lastDist = 0;

const COLOUR_FREE = "#66ff00";
const COLOUR_PRERESERVED = "orange";
const COLOUR_RESERVED = "red";
const COLOUR_PAYED = "blue";
const COLOUR_OWN = "yellow";

var stage = null;
//resizeCanvas();
var layer;
var imageObj;
var layer2;
//var textLayer;

var varaukset = {
    valitut: [],
    poistetut: [],
    hinta: 0,
    viimeksiKoskettu: undefined
};

const emails = ['username@gmail.com', 'user02@gmail.com'];

const MapPageInfo = () => {

    const classes = useStyles();
    const [selectElectricityDialogOpen, setSelectElectricityDialogOpen] = useState(false);
    const [lastUpdated, setUpdated] = useState(null);

    //const [size, setSize] = useState('');
    //const [prize, setPrize] = useState('');
    //const [spotID, setSpotID] = useState('');
    //const [e230V, setE230] = useState(false);
    //const [e400V, setE400] = useState(true);

    const handleClose = (value) => {
        setSelectElectricityDialogOpen(false);
        //setSelectedValue(value);
    };

    const handleListItemClick = (value) => {
        setSelectElectricityDialogOpen(value);
    };

    //const imageRef = useRef(null);
    const canvasRef = useRef(null);

    const places = useSelector(state => state.places);

    const dispatch = useDispatch();



    useEffect(() => {
        if (!places.isLoading || !lastUpdated ) {
            if ((!places.places || places.places.length <= 0 || !lastUpdated)) {
                //dispatch(loadMap());
                loadMap(dispatch);
                setUpdated(Date());
            } else if (!canvasRef.current.firstChild) {
                stage = getStage(width, height);
                //resizeCanvas();
                layer = new Konva.Layer();
                stage.add(layer);
                imageObj = getLMMapImage();
                layer2 = new Konva.Layer();
                //layer.hide();
                //layer2.zIndex(3);



                const ctx = canvasRef.current.getContext("2d");

                /*var kartta = new Konva.Image({
                    x: 0,
                    y: 0,
                    image: imageRef.current
                })*/

                //ctx.drawImage(imageRef.current, 0, 0);
                ctx.drawImage(imageObj, 0, 0);

                var processedSpots = [];
                Array.prototype.forEach.call(places.places, place => {
                    // if ((!is230V() && !is400V()) || (place.electric230v === "1" && is230V()) || (place.electric400v === "1" && is400V())) { */
                    if(!processedSpots.includes(place.spotID)) {
                        // if ((!is230V() && !is400V()) || (place.electric230v === "1" && is230V()) || (place.electric400v === "1" && is400V())) { */
                            addPlace(place.spotID, place.name, place.coordinates, place.status, false, varaukset, place.size, place.prize, place.electric230v, place.electric400v, layer2, COLOUR_FREE, COLOUR_PRERESERVED, COLOUR_RESERVED,COLOUR_PAYED, COLOUR_OWN);
                            processedSpots.push(place.spotID);
                        //}
                        }
                        
                    //}
                })

                var free = new Konva.Line({
                    points: [10,10,60,10,60,30,10,30],
                    fill: COLOUR_FREE,
                    stroke: 'black',
                    fillEnabled: true,
                    strokeWidth: 1,
                    //opacity: 0.7,
                    closed: true
                });
                var prereserver = new Konva.Line({
                    points: [10,40,60,40,60,60,10,60],
                    fill: COLOUR_PRERESERVED,
                    stroke: 'black',
                    fillEnabled: true,
                    strokeWidth: 1,
                    //opacity: 0.7,
                    closed: true
                });
                var reserver = new Konva.Line({
                    points: [10,70,60,70,60,90,10,90],
                    fill: COLOUR_RESERVED,
                    stroke: 'black',
                    fillEnabled: true,
                    strokeWidth: 1,
                    //opacity: 0.7,
                    closed: true
                });
                var payed = new Konva.Line({
                    points: [10,100,60,100,60,120,10,120],
                    fill: COLOUR_PAYED,
                    stroke: 'black',
                    fillEnabled: true,
                    strokeWidth: 1,
                    //opacity: 0.7,
                    closed: true
                });
                var freeText = new Konva.Text({
                    x: 15,
                    y: 15,
                    text: 'Vapaa',
                    fontSize: 12,
                    fontFamily: 'Calibri',
                    fill: '#000000',
                    width: 40,
                    height: 40,
                    //padding: 40,
                    align: 'center',
                    listening: false
                });
                var preText = new Konva.Text({
                    x: 12,
                    y: 45,
                    text: 'Ennakko',
                    fontSize: 12,
                    fontFamily: 'Calibri',
                    fill: '#000000',
                    width: 44,
                    height: 40,
                    //padding: 40,
                    align: 'center',
                    listening: false
                });
                var reservedText = new Konva.Text({
                    x: 14,
                    y: 75,
                    text: 'Varattu',
                    fontSize: 12,
                    fontFamily: 'Calibri',
                    fill: '#000000',
                    width: 44,
                    height: 40,
                    //padding: 40,
                    align: 'center',
                    listening: false
                });
                var payedText = new Konva.Text({
                    x: 12,
                    y: 105,
                    text: 'Maksettu',
                    fontSize: 12,
                    fontFamily: 'Calibri',
                    fill: '#000000',
                    width: 46,
                    height: 40,
                    //padding: 40,
                    align: 'center',
                    listening: false
                });
                freeText.moveToTop();
                
                layer2.add(free);
                layer2.add(freeText);
                layer2.add(prereserver);
                layer2.add(preText);
                layer2.add(reserver);
                layer2.add(reservedText);
                layer2.add(payed);
                layer2.add(payedText);

                stage.add(layer2);
            }
        } else if (stage) {
            resizeCanvas();
        }
    });

    return (
        <div className={classes.root}>

            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={selectElectricityDialogOpen}>
                <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>
                <List>
                    {emails.map((email) => (
                        <ListItem button onClick={() => handleListItemClick(email)} key={email}>
                            <ListItemText primary={email} />
                        </ListItem>
                    ))}

                    <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
                        <ListItemText primary="Add account" />
                    </ListItem>
                </List>
            </Dialog>
            <div id="mapContainer" className={classes.mapContainer}>

                <canvas ref={canvasRef} className={classes.canvas} />
                {/* <img ref={imageRef} src='https://www.lcmynamaki.fi/wp-content/kartta.svg' className={classes.hidden} /> */}
            </div >
        </div >
    );
}

function getStage(aWitdth, aHeight) {

    Konva.hitOnDragEnabled = true;

    var stage = new Konva.Stage({
        container: 'mapContainer',
        preventDefault: false,
        height: aHeight,
        width: aWitdth,
        scaleX: 1,
        scaleY: 1,
        draggable: true
    });

    stage.on('wheel', function (event) {
        var touchPos = stage.getPointerPosition();
        var bx = stage.getX();
        var by = stage.getY();
        var bsx = stage.getScaleX();
        var bsy = stage.getScaleY();
        if (event.evt.deltaY < 0) {
            if (stage.getScaleX() > 10) {
                return;
            }
            stage.setScaleX(stage.getScaleX() / scaleMultiplier);
            stage.setScaleY(stage.getScaleY() / scaleMultiplier);
            stage.draw();
        } else {
            var newScale = stage.getScaleX() * scaleMultiplier;

            if (newScale < stage.minScale) {
                newScale = stage.minScale;
            }
            stage.setScaleX(newScale);
            stage.setScaleY(newScale);
            stage.draw();
        }

        var newX = touchPos.x - stage.getScaleX() * (touchPos.x - bx) / bsx;
        var newY = touchPos.y - stage.getScaleY() * (touchPos.y - by) / bsy;


        stage.setX(newX);
        stage.setY(newY);
        relocateCanvas(false); 
        stage.draw();
    });



    stage.on("dragend", function(e) {        
        relocateCanvas(true);        
    });

    stage.on('touchmove', function (e) {
        e.evt.preventDefault();

        var bx = stage.getX();
        var by = stage.getY();
        var bsx = stage.getScaleX();
        var bsy = stage.getScaleY();

        var touch1 = e.evt.touches[0];
        var touch2 = e.evt.touches[1];

        if (touch1 && touch2) {
            stage.multitouch = true;

            var dist = getDistance(
                {
                    x: touch1.clientX,
                    y: touch1.clientY
                },
                {
                    x: touch2.clientX,
                    y: touch2.clientY
                }
            );

            if (!lastDist) {
                lastDist = dist;
            }

            var scale = (stage.scaleX() * dist) / lastDist;

            stage.scaleX(scale);
            stage.scaleY(scale);

            var tx = (touch1.clientX + touch2.clientX) / 2;
            var ty = (touch1.clientY + touch2.clientY) / 2;


            var newX = tx - scale * (tx - bx) / bsx;
            var newY = ty - scale * (ty - by) / bsy;

            stage.setX(newX);
            stage.setY(newY);

            stage.draw();
            lastDist = dist;
        }
    });

    stage.on('touchend', function () {
        lastDist = 0;
    });

    stage.on('dbltap', function () {
        if (stage.multitouch) {
            stage.multitouch = false;
            return;
        }
        stage.setDraggable(!stage.getDraggable());

        /* TODO
        if (stage.getDraggable()) {
            document.getElementById("lukitus").style.visibility = "hidden";
        } else {
            document.getElementById("lukitus").style.visibility = "visible";
        }
        */
    });

    window.addEventListener('resize', resizeCanvas, false);

    /*
    window.onload = function () {
        // add button event listeners
        document.getElementById("plus").addEventListener("click", function () {
            stage.setScaleX(stage.getScaleX() / scaleMultiplier);
            stage.setScaleY(stage.getScaleY() / scaleMultiplier);
            stage.draw();
        }, false);
    
        document.getElementById("minus").addEventListener("click", function () {
    
            var newScale = stage.getScaleX() * scaleMultiplier;
    
            if (newScale < stage.minScale) {
                newScale = stage.minScale;
            }
    
            stage.setScaleX(newScale);
            stage.setScaleY(newScale);
            stage.draw();
        }, false);
    
    }
    */

    return stage;

}

function relocateCanvas(draw) {
    var stageW = stage.scaleX() * imageObj.width;        
    var stageH = stage.scaleX() * imageObj.height; 
    var update = false;
    if(stage.getX() + stageW <= width && stage.getX() <= 0) {
        if(stageW > width) {
            stage.setX(width-stageW);
        } else {
            stage.setX(0);
        }
        update = true;
    }
    if(stage.getY() + stageH <= height && stage.getY() <= 0) {
        if(stageH > height) {
            stage.setY(height-stageH);
        } else {
            stage.setY(0);
        }
        update = true;
    }
    if(stage.getX() + stageW > width && stage.getX() > 0 ) {
        if(stageW > width) {
            stage.setX(0);
        } else {
            stage.setX(width-stageW);
        }
        update = true;
    }
    if(stage.getY() + stageH > height && stage.getY() > 0) {
        if(stageH > height) {
            stage.setY(0);
        } else {
            stage.setY(height-stageH);
        }
        update = true;
    }

    if(update && draw) {
        stage.draw();
    }
}

function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function resizeCanvas() {
    height = window.innerHeight - 64;
    width = window.innerWidth;
    stage.setWidth(width);
    stage.setHeight(height);
    CenterAndScaleStage();
    
    stage.draw();
}

function CenterAndScaleStage() {
    var scale1 = height / imageObj.height;
    var scale2 = width / imageObj.width;

    if (scale1 < scale2) {
        stage.setScaleX(scale1);
        stage.setScaleY(scale1);
        stage.setY(0);

        stage.setX((width - (imageObj.width) * scale1) / 2);

    } else {
        stage.setScaleX(scale2);
        stage.setScaleY(scale2);
        stage.setX(0);
        //stage.setY((height - (imageObj.height) * scale2) / 2);
        stage.setY(0);
    }
    stage.minScale = stage.getScaleX();
}

function getLMMapImage() {
    var image = new Image();
    image.onload = function () {
        var kartta = new Konva.Image({
            x: 0,
            y: 0,
            image: imageObj
        });

        if (imageObj) {
            var scale1 = height / imageObj.height;
            var scale2 = width / imageObj.width;

            if (scale1 < scale2) {
                stage.setScaleX(scale1);
                stage.setScaleY(scale1);
                stage.setY(0);

                stage.setX((width - (image.width) * scale1) / 2);

            } else {
                stage.setScaleX(scale2);
                stage.setScaleY(scale2);
                stage.setX(0);
                //stage.setY((height - (image.height) * scale2) / 2);
                stage.setY(0);
            }
            stage.minScale = stage.getScaleX();
        }


        // add the shape to the layer
        layer.add(kartta);
        stage.batchDraw();
    };
    //image.src = 'https://www.lcmynamaki.fi/wp-content/kartta.svg';
    image.src = 'https://lauri.lcmynamaki.fi/kartta_2022.svg';

    return image;
}


function  addPlace(id, aName, aPoints, status, omaVaraus, varaukset, aKoko, aHinta, elec230, elec400, aLayer, colourFree, colourPreReserved, colourReserved, colourPayed, colourOwn) {
    //function addPlace(aName, aPoints, status, aLayer) {

    let coordinates;

    if (!Array.isArray(aPoints)) {
        let tmp = aPoints.replace("[", "");
        tmp = tmp.replace("]", "");
        coordinates = tmp.split(`,`).map(x => +x);
    } else {
        coordinates = aPoints;
    }

    var bgColor = colourFree;

    if (status === 'Ennakkovaraus') {
        bgColor = colourPreReserved;
    }

    if (status === 'Varattu') {
        bgColor = colourReserved;
    }

    if (status === 'Maksu paikalla') {
        bgColor = colourReserved;
    } 

    if (status === 'Laskutettu') {
        bgColor = colourReserved;
    }

    if (status === 'Maksettu') {
        bgColor = colourPayed;
    }

    if (omaVaraus) {
        bgColor = colourOwn;
    }

    var electricity = "Ei";

    if (elec230 === "1" && elec400 === "1") {
        electricity = "230V / 400V";
    } else if (elec230 === "1") {
        electricity = "230V";
    } else if (elec400 === "1") {
        electricity = "400V";
    }

    var aPoly = new Konva.Line({
        name: aName,
        koko: aKoko,
        hinta: aHinta,
        points: coordinates,
        status: status,
        electricity: electricity,
        fill: bgColor,
        stroke: 'black',
        strokeEnabled: false,
        opacity: 0.5,
        closed: true
    });

    if (omaVaraus) {
        aPoly.selected = true;
    }

    var aPolyLine = new Konva.Line({
        points: coordinates,
        fill: bgColor,
        stroke: 'black',
        fillEnabled: false,
        strokeWidth: 1,
        //opacity: 0.7,
        closed: true
    });

    if (status === 'Vapaa' || omaVaraus) {
        aPoly.on('mouseout', function () {
            aPolyLine.setStroke('black');
            aLayer.draw();
        });

        aPoly.on('mouseover', function () {
            aPolyLine.moveToTop();
            aPolyLine.setStroke('yellow');
            aLayer.draw();
        });


        /*
        aPoly.on('click', function () {
            selectPlace(aPoly, aLayer, varaukset);
        });

        aPoly.on('tap', function () {
            selectPlace(aPoly, aLayer, varaukset);
        });
        */

        aPoly.on('touchmove', function () {
            if (typeof (varaukset.viimeksiKoskettu) !== 'undefined') {
                varaukset.viimeksiKoskettu.fire('touchend');
            }
            varaukset.viimeksiKoskettu = aPoly;
            aPolyLine.moveToTop();
            aPolyLine.setStroke('yellow');
            aLayer.draw();

        });

        aPoly.on('touchend', function () {
            aPolyLine.setStroke('black');
            aLayer.draw();
        });

    }

    aLayer.add(aPoly);
    aLayer.add(aPolyLine);
}


export { MapPageInfo as default };

