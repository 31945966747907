//export const GET_ITEMS = 'GET_ITEMS';
//export const ADD_ITEM = 'ADD_ITEM';
//export const DELETE_ITEM = 'DELETE_ITEM';
//export const ITEMS_LOADING = 'ITEMS_LOADING';

//AUTH REDUCER
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const RENEW_SUCCESS = "RENEW_SUCCESS";
export const RENEW_FAIL = "RENEW_FAIL";
export const COMPANY_ADDED = "COMPANY_ADDED";
export const COMPANY_ADD_FAILS = "COMPANY_ADD_FAILS";
export const MY_COMPANIES_LOADING = "MY_COMPANIES_LOADING";
export const MY_COMPANIES_LOADED= "MY_COMPANIES_LOADED";
export const MY_COMPANIES_FAILED = "MY_COMPANIES_FAILED";

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const MAP_LOAD = 'MAP_LOAD';
export const MAP_LOADING = 'MAP_LOADING';
export const MAP_LOADED = 'MAP_LOADED';
export const ADD_RESERVATION = 'ADD_RESERVATION';
export const REMOVE_RESERVATION = 'REMOVE_RESERVATION';
export const CLEAR_RESERVATIONS = "CLEAR_RESERVATIONS";

export const EDIT_RESERVATION = "EDIT_RESERVATION";

//ADMIN REDUCER
export const USERS_LOADED = "USERS_LOADED";
export const USERS_LOADING = "USERS_LOADING";
export const USER_ADDED = "USER_ADDED";
export const ADD_USER_FAILS = "ADD_USER_FAILS";
export const ADDING_USER = "ADDING_USER";
export const USER_COMPANIES_LOADING = "USER_COMPANIES_LOADING";
export const USER_COMPANIES_LOADED= "USER_COMPANIES_LOADED";
export const USER_COMPANIES_FAILED = "USER_COMPANIES_FAILED";



