import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { register } from '../../flux/actions/authActions';
import Container from '@material-ui/core/Container';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from "react-router-dom";
import Messages from '../Messages';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" >
      { 'Copyright © '}
      < Link color="inherit" href="https://material-ui.com/" >
        Lions Club Mynämäki ry -
                    </Link>{' '}
      { new Date().getFullYear()}
      { '.'}
    </Typography>
  );
}

let history;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflowY: "scroll",
  },
  image: {
    backgroundImage: 'url(https://www.lcmynamaki.fi/wp-content/uploads/2017/02/Lauri15_ilma5m.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageText: {
    //color: '#E9E969',
    backgroundColor: 'rgba(51, 170, 51, .4)',
    color: '#FFF',
    textAlign: "center",
  },
  modalHeader: {
    textAlign: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  footer: {
    color: '#FFF',
    //position: 'fixed',
    //top: '80vh',
    bottom: 0,
    overflow: 'hidden',
    //display: "fixed",
    //width: "100%",
    //top: 0,
    //padding: 0,
    //minWidth: '100vw',
    textAlign: 'center',
    //verticalAlign: 'middle',
    backgroundColor: 'rgba(51, 170, 51, .4)',
  }, footerText: {
  },
  logo: {
    width: '30px',
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: 'none',
  },
  logo2: {
    width: '80px',
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: 'none',
  }
}));

const RegisterModal = () => {
  const [modal, setModal] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState(null);

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const isRegistered = useSelector(state => state.auth.isRegistered)
  const error = useSelector(state => state.error);

  const classes = useStyles();
  history = useHistory();

  const dispatch = useDispatch();

  const handleToggle = useCallback(() => {
    // Clear errors
    setModal(!modal);
  }, [modal]);



  const handleOnSubmit = (e) => {
    e.preventDefault();

    // Create user object
    const user = {
      name,
      phone,
      email,
      password
    };

    // Attempt to login
    dispatch(register(user));
  };

  useEffect(() => {
    // Check for register error
    if (error.id === 'REGISTER_FAIL') {
      setMsg(error.msg.msg);
    } else {
      setMsg(null);
    }

    // If authenticated, close modal
    if (modal) {
      if (isAuthenticated) {
        handleToggle();
        history.push("/paikkavaraus");
      } else if (isRegistered) {
        handleToggle();
        history.push("/kirjaudu");
      }
    }
  }, [error, handleToggle, isAuthenticated, isRegistered, modal]);

  return (
    <div>
      <Modal
        open={modal}
        onClose={handleToggle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid container component="main" className={classes.root} >
          <CssBaseline />
          <Hidden only={['sm', 'xs']}>
            <Grid item md={9} className={classes.image} >
              <Container className={classes.headingBackGroud} disableGutters>
                <Typography className={classes.imageText} variant="h3">
                  Laurin Markkinat
                    </Typography>
                <Typography className={classes.imageText} variant="h6">
                  Mynämäellä lauantaina 10.8.2024
                    </Typography>
              </Container>
              <Container className={classes.footer} disableGutters>
                <Typography className={classes.footerText} variant="h6">
                  Järjestäjä
                </Typography>
                <img className={classes.logo} alt="" src={process.env.PUBLIC_URL + "/icon.gif"} />
                <Typography className={classes.footerText} variant="h6">
                  Lions Club Mynämäki ry
                </Typography>
              </Container>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={3} component={Paper} elevation={6} square >
            <div className={classes.paper}>
              <Typography component="h1" variant="h5" className={classes.modalHeader}>
                Laurin Markkinoiden Paikkavaraus
                <hr />
              </Typography>
              <br />
              <img className={classes.logo2} alt="" src={process.env.PUBLIC_URL + "/logo.png"} /> 
              <Typography component="h1" variant="h5" >
                Rekisteröidy
                            </Typography>
              {msg ? <Alert severity="error">{msg}</Alert> : null}
              <Messages/>
              <form className={classes.form} noValidate onSubmit={handleOnSubmit} >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Nimi"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  onChange={e => setName(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  label="Puhelin"
                  name="phone"
                  autoComplete="phonenumber"
                  autoFocus
                  onChange={e => setPhone(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Sähköposti"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Salasana"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={e => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Rekisteröidy
            </Button>
                <Grid container direction="row" justify="space-evenly" alignItems="center">
                  <Grid item xs={2}>
                    <Link href="/" variant="body2" >
                      <HomeIcon />
                    </Link>
                  </Grid>
                  <Grid item xs={2}>
                    <Link href="kirjaudu" variant="body2" >
                      Kirjaudu
            </Link>
                  </Grid>
                </Grid>
                < Box mt={5} >
                  <Copyright />
                </Box>

              </form>

            </div>

          </Grid>

        </Grid>

      </Modal>

    </div >
  );
};

export { RegisterModal as default };

