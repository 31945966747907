import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { loadMap } from '../flux/actions/marketplaceActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { DataGrid } from '@mui/x-data-grid';


const useStyles = makeStyles((theme) => ({
    /*
    root: {
        //position: "relative",
        height: '100vh',
        width: '100vw',
        backgroundPosition: "center",
        //marginTop: 0,
        //backgroundImage: 'url(https://www.lcmynamaki.fi/wp-content/kartta.svg)',
        //backgroundRepeat: 'no-repeat',
        //backgroundSize: 'cover',
        //backgroundPosition: "center",
        //backgroundColor: "#333",
        //marginTop: "75px",
        //overflowY: "scroll",
        display: 'flex',
        justifycontent: 'center',
        alignitems: 'center',
    },
    */
    root: {
        height: '100vh',
        //witdh: '100vw',
        //padding: '20px',
        //margin: '150 10 10 10',
        //overflowY: "scroll",
        //flex: "1 1 auto",
        display: "flex",
        //alignSelf:'stretch',
        justify: 'center',
        alignItems: 'center',
        //backgroundColor: "#FFF",
    },
    paper: {
        //margin: theme.spacing(10,20,20,20),  
        //marginTop: '20',      
        //padding: '5px',
        //witdh: '70%',
        //display: 'flex:1',
        //flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#FFF"
    },
    mapContainer: {
        backgroundColor: "#FFF",
        //paddingTop: "64px",
        marginTop: "64px",
        //height: "100vh - 64px",
        width: '100vw',
    },
    info: {
        position: "fixed",
        top: 80,
        left: 10,
        border: "2px solid green",
        borderRadius: "5px",
        zIndex: '4',

        backgroundColor: 'rgba(0, 0, 0, .5)',
    },
    button: {
        zIndex: '0',
    }


}));

const columns = [
    { field: 'id', hide: true },
    { field: 'spotID', hide: true },
    {
      field: 'name',
      headerName: 'Paikka',
      width: 80,
      editable: false,
    },
    {
      field: 'company',
      headerName: 'Yritys',    
      width: 250,
      editable: false,
    },
    {
      field: 'size',
      headerName: 'Koko',
      //type: 'phonenumber',
      width: 100,
      editable: false,
    },
    {
        field: 'electricityType',
        headerName: 'Sähkö',
        //type: 'phonenumber',
        width: 120,
        editable: false,
      },
    {
        field: 'status',
        headerName: 'Tila',
        //type: 'phonenumber',
        width: 150,
        editable: false,
      },
      {
        field: 'phone',
        headerName: 'Puhelinnumero',
        //type: 'phonenumber',
        width: 150,
        editable: false,
      },
      {
        field: 'email',
        headerName: 'Sähköposti',
        //type: 'phonenumber',
        width: 250,
        editable: false,
      },
      {
        field: '',
        headerName: 'Avaa',
        renderCell: (params) => (
          <strong>
            
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 0 }}
            >
              Näytä
            </Button>
          </strong>
        ),
      }
    /*
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.getValue(params.id, 'firstName') || ''} ${
          params.getValue(params.id, 'lastName') || ''
        }`,
    },
    */
  ];

const SpotList = () => {
    const classes = useStyles();
    
    const places = useSelector(state => state.places);
    
    const dispatch = useDispatch();


    function setElectricity(value) {

    }


    useEffect(() => {
        if (!places.isLoading) {
            if ((!places.places || places.places.length <= 0)) {
                loadMap(dispatch);
            }
        }
    },[]);

    
                /* <Typography key="content" variant="h5" color="primary" component="div"> 
                <td>{place.name}</td>
                <td>{place.company ? place.company : ""}</td>
                <td>{place.status}</td>
            </Typography> */

    return (
        <div className={classes.root}>  


                <Container className={classes.paper}>    

                <Typography component="h1" variant="h5" className={classes.modalHeader}>
                    Myyntipaikat ja varaukset
                    <hr />
                </Typography>

                   <div style={{  width: '100%' }}>
                            <DataGrid
                                rows={places.places}
                                autoHeight={true}
                                columns={columns}
                                pageSize={15}
                                density='compact'
                                rowsPerPageOptions={[10,25,50,100]}
                                //checkboxSelection
                                //disableSelectionOnClick
                            />    
                            </div>    

            
                        {/*
                        <Button
                        //type="submit"                  
                        variant="contained"
                        color="primary"
                        onClick={e => setElectricity("Ei sähköä")}
                        className={classes.button}
                      >
                        Ei sähköä
                      </Button> 
                      */}
                  </Container>
        </div>

    );
}

export { SpotList as default };