
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RENEW_SUCCESS,
  RENEW_FAIL,
  MY_COMPANIES_LOADING,
  MY_COMPANIES_LOADED,
  MY_COMPANIES_FAILED
} from '../actions/types';

const initialState = {
  token: null, //localStorage.getItem('token'),
  isRegistered: false,
  isAuthenticated: false,
  isLoading: false,
  user: null,
  myCompanies: {
    isLoading: false,
    companies: []
  }
};


export default function reducer(state = initialState, action: any) {

  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      };
    case LOGIN_SUCCESS:
      //localStorage.setItem('token', action.payload.token);      
      return {
        ...state,
        ...action.payload,
        isRenewed: false,
        isRegistered: false,
        isAuthenticated: true,
        isLoading: false
      };
    case REGISTER_SUCCESS:
      //localStorage.removeItem('token');      
      return {
        ...state,
        token: null,
        user: null,
        isRegistered: true,
        isAuthenticated: false,
        isLoading: false
      };
    case RENEW_SUCCESS:
      //localStorage.removeItem('token');      
      return {
        ...state,
        token: null,
        user: null,
        isRenewed: true,
        isRegistered: false,
        isAuthenticated: false,
        isLoading: false
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case RENEW_FAIL:
    case REGISTER_FAIL:
      //localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        user: null,
        isRenewed: false,
        isRegistered: false,
        isAuthenticated: false,
        isLoading: false,
        myCompanies: {
          isLoading: false,
          companies: [],
        }
      };
    case MY_COMPANIES_LOADING:
      return {
        ...state,
        myCompanies: {
          isLoading: true,
          companies: []
        }
        
      };
    case MY_COMPANIES_LOADED:
      return {
        ...state,
        myCompanies: {
          isLoading: false,
          companies: action.payload
        }        
      };
      case MY_COMPANIES_FAILED:
        return {
          ...state,
          myCompanies: {
            isLoading: false,
            companies: []
          }        
        };
    default:
      return state;
  }
}
