import axios from 'axios';
import { returnErrors } from './errorActions';
import { tokenConfig } from './tokenUtils';


import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RENEW_SUCCESS,
  RENEW_FAIL,
  MY_COMPANIES_LOADING,
  MY_COMPANIES_LOADED,
  MY_COMPANIES_FAILED,
  COMPANY_ADDED,
  COMPANY_ADD_FAILS
} from './types';

// Check token & load user
export const loadUser = () => (dispatch) => {
  // User loading
  dispatch({ type: USER_LOADING });

  axios
    .post('https://lauri.lcmynamaki.fi/login?action=auth', {}, tokenConfig())
    .then(res =>
      dispatch({
        type: USER_LOADED,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.data) {
        dispatch(returnErrors(err.response.data, err.response.status, 'AUTH_ERROR'));
      } else {
        dispatch(returnErrors("Odottomaton virhe: " + err.message, 404, 'AUTH_ERROR'));
      }
      dispatch({
        type: AUTH_ERROR
      });
    });
};

// Register User
export const register = ({ name, phone, email, password }) => (
  dispatch
) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // Request body
  const body = JSON.stringify({ name, phone, email, password });

  axios
    .post('https://lauri.lcmynamaki.fi/login?action=register', body, config)
    .then(res =>
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data
      })
    )
    .catch(err => {      
      console.log(err);   
      if (err.response) {   
        dispatch(returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL'));
      } else { 
        dispatch(returnErrors("Odottomaton virhe: " + err.message, 404, 'REGISTER_FAIL'));  
      }
      dispatch({
        type: REGISTER_FAIL
      });
    });
};

// Register User
export const renew = ({ email }) => (
  dispatch
) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // Request body
  const body = JSON.stringify({ email });

  axios
    .post('https://lauri.lcmynamaki.fi/login?action=renew', body, config)
    .then(res =>
      dispatch({
        type: RENEW_SUCCESS,
        payload: res.data
      })
    )
    .catch(err => {      
      console.log(err);      
      dispatch(
        returnErrors(err.response.data, err.response.status, 'RENEW_FAIL')
      );
      dispatch({
        type: RENEW_FAIL
      });
    });
};

// Login User
export const login = ({ email, password }) => (
  dispatch
) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
      //'Access-Control-Allow-Origin': 'https://lauri.lcmynamaki.fi'
      //'Access-Control-Allow-Origin': 'http://localhost:3000'
    }
  };

  // Request body
  const body = JSON.stringify({ action: "Login", email, password });

  //console.log(body);

  axios
    .post('https://lauri.lcmynamaki.fi/login?action=login', body, config)
    //.post('https://lcmynamaki.fi/lmAPI.php?action=login', body, config)
    .then(res => {
      console.log(res);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      })
    }
    )
    .catch(err => {
      console.log(err);
      if (err.data) {
        dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
      } else {
        dispatch(returnErrors("Odottomaton virhe: " + err.message, 404, 'LOGIN_FAIL'));
      }
      dispatch({
        type: LOGIN_FAIL
      });
    });
};

// Logout User
export const logout = () => (  
  dispatch
) => {
  dispatch({
    type: LOGOUT_SUCCESS
  });
};



export const getMyCompanies = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: MY_COMPANIES_LOADING });

  var headers = tokenConfig();

  //console.log(headers);
  axios
  //.post('https://lauri.lcmynamaki.fi/headers', {}, headers)
  .post('https://lauri.lcmynamaki.fi/getMyCompanies', {}, headers)
  //.post('https://lauri.lcmynamaki.fi/login?action=myComp', {}, headers )
    .then(res => {
        console.log("GET MY COMPANIES");
        console.log(res.data);
        dispatch({type: MY_COMPANIES_LOADED,payload: res.data});
      }
    )
    .catch(err => {
      var errType = MY_COMPANIES_FAILED;
      if (err.response) {
        dispatch(returnErrors(err.response.data.msg, err.response.status, 'MY_COMPANIES_LOADED'));
        if(err.response.status === 401) {
          //errType = AUTH_ERROR;
        }
      } 
      else if (err.data) {
        dispatch(returnErrors(err.data.msg, err.data.htmlCode, 'MY_COMPANIES_LOADED'));
      }
      else {
        dispatch(returnErrors("Odottomaton virhe: " + err.msg, 404, 'MY_COMPANIES_LOADED'));
      }
      
      dispatch({ type: errType });        
    });
};

export const getMyCompanies2 = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: MY_COMPANIES_LOADING });

  var headers = tokenConfig();

  console.log(headers);

  axios
    .post('https://lauri.lcmynamaki.fi/getMyCompanies', {}, headers)
    //.post('https://lauri.lcmynamaki.fi/login?action=myComp', {}, headers )
    .then(res => {
        console.log("GET MY COMPANIES");
        console.log(res);
        dispatch({type: MY_COMPANIES_LOADED,payload: res.data});
      }
    )
    .catch(err => {
      var errType = MY_COMPANIES_FAILED;
      if (err.response) {
        dispatch(returnErrors(err.response.data.msg, err.response.status, 'MY_COMPANIES_LOADED'));
        if(err.response.status === 401) {
          //errType = AUTH_ERROR;
        }
      } 
      else if (err.data) {
        dispatch(returnErrors(err.data.msg, err.data.htmlCode, 'MY_COMPANIES_LOADED'));
      }
      else {
        dispatch(returnErrors("Odottomaton virhe: " + err.msg, 404, 'MY_COMPANIES_LOADED'));
      }
      
      dispatch({ type: errType });        
    });
};


export const addCompany = (dispatch, { name, phone, invoicingEmail, publicEmail, address, homepage, facebook, instagram }, dataGridId) => {


  // Request body
  const body = JSON.stringify({ name, phone, invoicingEmail, publicEmail, address, homepage, facebook, instagram });
  
    axios.post('https://lauri.lcmynamaki.fi/addCompany', body, tokenConfig())

    .then(res => {
    res.data.id = dataGridId;
    dispatch({ type: COMPANY_ADDED, payload: res.data })
    return true; 
  } )
  .catch (err => {
    console.log(err);
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status, 'COMPANY_ADD_FAILS'));
    } else {
      dispatch(returnErrors("Odottomaton virhe: " + err.message, 404, 'COMPANY_ADD_FAILS'));
    }
    dispatch({ type: COMPANY_ADD_FAILS });
    return false;
  });
};