import React, { useState,  useEffect } from 'react';
import AddUserModal from './AddUserModal';
import UserModal from './UserModal';
import Button from '@material-ui/core/Button';



function ShowModal(props) {

    const [modalIsOpen, setIsOpen] = useState(false);    
                
    useEffect(() => {

      },[modalIsOpen]);

    function showModal() {        
        setIsOpen(true);            
    }

    function handleSubmitModal(event, data) {
        console.log(data);
        setIsOpen(false);
        props.onCloseModalSubmit(event,data);    
    }    

    function handleCloseModal(event, data) {
        //console.log(data);
        setIsOpen(false);   
        props.onCloseModalCancel(event,data); 
    }

    return (
            <div>
            <Button variant="contained" color="primary" size="small" style={{ marginLeft: 0 }} startIcon={props.startIcon} onClick={showModal}>{props.buttonName}</Button>
            { props.children === 'UserModal' ?
            <UserModal modalIsOpen={modalIsOpen} handleCloseModal={handleCloseModal} handleSummitModal={handleSubmitModal} {...props}/> : null }
            { props.children === 'AddUserModal' ?
            <AddUserModal modalIsOpen={modalIsOpen} handleCloseModal={handleCloseModal} handleSummitModal={handleSubmitModal} {...props}/> : null }
            
            {/*
            {props.children}
            renderModal}
            <UserModal IsModalOpened={modalIsOpen} {...subProps}//email={email}
            />
            */}
            </div>       
        
    );
};

export default ShowModal;

